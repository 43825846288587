var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.hasDuplicateConfigs)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"ml-3",attrs:{"color":"warning"}},on),[_vm._v("mdi-alert")])]}}],null,false,4211453091)},[_c('span',[_vm._v(" "+_vm._s(_vm.$lang("commonConfig.duplicationConfigs"))+" ")])]):_vm._e(),(_vm.editable)?[_c('config-import-menu',{attrs:{"response-id":_vm.value.id},on:{"copyResponse":function (ref) {
	var payload = ref.payload;
	var field = ref.field;

	return _vm.emitCopyResponse(payload, field);
}}}),(_vm.isAbTestConfigPage && _vm.abTestResponses.length > 1)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"atid":"transfer_button","color":_vm.dark ? 'secondary' : '',"fab":"","x-small":""},on:{"click":function($event){_vm.isCopyDialog = true}}},on),[_c('v-icon',[_vm._v("mdi-content-copy")])],1)]}}],null,false,212163707)},[_c('span',[_vm._v(" "+_vm._s(_vm.$lang("abTestConfig.copyFromOtherGroups"))+" ")])]):_vm._e(),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"atid":"transfer_button","color":_vm.dark ? 'secondary' : '',"fab":"","x-small":"","disabled":!_vm.hasDiffStageAndProd},on:{"click":function($event){return _vm.$emit('transfer', !_vm.isStageTab)}}},on),[_c('v-icon',[_vm._v(_vm._s(_vm.iconForTransferButton))])],1)]}}],null,false,220249544)},[_c('span',[_vm._v(" "+_vm._s(_vm.hintForTransferButton)+" ")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"blur",rawName:"v-blur"}],staticClass:"ml-3",attrs:{"atid":"deploy_button","color":"orange","fab":"","x-small":"","disabled":!_vm.hasNonDeployedChanges || _vm.isDeploymentInProgress,"loading":_vm.isDeploymentInProgress},on:{"click":function($event){_vm.confirmDialog = true}}},on),[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-cloud-upload-outline")])],1)]}}],null,false,273913276)},[_c('span',[_vm._v(" "+_vm._s(_vm.hintForDeployButton)+" ")])])]:_vm._e(),_c('config-response-confirm-dialog',{attrs:{"preview-items":_vm.previewItems,"is-stage-tab":_vm.isStageTab,"has-deploy-access":_vm.hasDeployAccess},on:{"deploy":function (value) { return _vm.$emit('deploy', value); }},model:{value:(_vm.confirmDialog),callback:function ($$v) {_vm.confirmDialog=$$v},expression:"confirmDialog"}}),_c('v-dialog',{attrs:{"width":"450"},model:{value:(_vm.isCopyDialog),callback:function ($$v) {_vm.isCopyDialog=$$v},expression:"isCopyDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$lang("abTestConfig.copyFromOtherGroups"))+" ")]),_c('v-card-text',[_c('v-list',_vm._l((_vm.localAbTestResponses),function(abTestResponse){return _c('v-list-group',{key:abTestResponse.id,scopedSlots:_vm._u([{key:"activator",fn:function(){return [_c('v-list-item-title',[_vm._v(" "+_vm._s(abTestResponse.segmentName)+" ")])]},proxy:true}],null,true)},[_c('v-list-item',{staticClass:"ml-3",attrs:{"link":""},on:{"click":function($event){return _vm.emitCopyResponse(abTestResponse, 'stage')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$lang("commonConfig.stage")))])],1),_c('v-list-item',{staticClass:"ml-3",attrs:{"link":""},on:{"click":function($event){return _vm.emitCopyResponse(abTestResponse, 'production')}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.$lang("commonConfig.production")))])],1)],1)}),1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }