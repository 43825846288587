




















































































































































import { Component, Vue, Prop } from "vue-property-decorator";

import AbTestConfigurationModel from "../models/AbTestConfigurationModel";
import AbTestResponseModel from "../models/AbTestResponseModel";
import ApplicationResponseModel, {
  EnvironmentType,
} from "../models/ApplicationResponseModel";
import { ConfigsStatus } from "../models/ConfigsStatusModel";
import TargetedConfigurationModel from "../models/TargetedConfigurationModel";
import { AppSection, CurrentUserModel } from "@/shared/models";

@Component
export default class ConfigImportMenu extends Vue {
  @Prop() responseId?: number;

  readonly STATUSES = Object.values(ConfigsStatus).filter(
    (status) =>
      ![ConfigsStatus.CANCELED, ConfigsStatus.DISABLED].includes(status)
  );
  readonly MIN_SEARCH_SYMBOLS = 2;
  readonly EnvironmentType = EnvironmentType;
  visible = false;
  search: string | null = null;
  source = EnvironmentType.STAGE;

  get appId(): string {
    return this.$route.params.id;
  }

  get currentUser(): CurrentUserModel {
    return this.$store.state.userStore.currentUser;
  }

  get hasAccessForTargetConfigs(): boolean {
    // TODO: NGAC need to check functionality before delete comment
    return this.currentUser.viewAccessEntities[this.appId].includes(
      AppSection.TARGETED_CONFIGS
    );
  }

  get isAbTestConfigsLoading(): boolean {
    return this.$store.state.abTestConfig.loadingConfig;
  }

  get searchInLowerCase(): string {
    return this.search ? this.search.toLowerCase() : "";
  }

  get abTests(): Array<AbTestConfigurationModel> {
    return [];

    // TODO: add AB Test config copying support later
    // if (this.searchInLowerCase.length < this.MIN_SEARCH_SYMBOLS) {
    //   return [];
    // }

    // return Object.entries(
    //   this.$store.state.abTestConfig.abTestConfigs as Record<
    //     string,
    //     Array<AbTestConfigurationModel>
    //   >
    // ).reduce(
    //   (
    //     result: Array<AbTestConfigurationModel>,
    //     [status, configs]: [string, Array<AbTestConfigurationModel>]
    //   ) => {
    //     if (!this.STATUSES.includes(status as ConfigsStatus)) {
    //       return result;
    //     }

    //     return [
    //       ...result,
    //       ...configs.filter(
    //         ({ name, responses }: AbTestConfigurationModel) =>
    //           name.toLowerCase().includes(this.searchInLowerCase) ||
    //           responses.some(({ segmentName }) =>
    //             segmentName.toLowerCase().includes(this.searchInLowerCase)
    //           )
    //       ),
    //     ];
    //   },
    //   []
    // );
  }

  get targetedConfigs(): Array<TargetedConfigurationModel> {
    if (this.searchInLowerCase.length < this.MIN_SEARCH_SYMBOLS) {
      return [];
    }

    return this.$store.state.targetedConfig.targetedConfigs.filter(
      ({ name }: TargetedConfigurationModel) =>
        name.toLowerCase().includes(this.searchInLowerCase)
    );
  }

  created() {
    const unwatchPopoverVisibility = this.$watch(
      "visible",
      (value: boolean) => {
        if (!value) {
          return;
        }

        this.$store.dispatch("loadTargetedConfigsByStatuses", this.STATUSES);
        // TODO: add AB Test config copying support later
        // this.$store.dispatch("loadAbTestConfigsByStatuses", this.STATUSES);
        unwatchPopoverVisibility();
      }
    );
  }

  copyResponse(payload: AbTestResponseModel | ApplicationResponseModel) {
    this.$emit("copyResponse", {
      payload,
      field: { STAGE: "stage", PROD: "production" }[this.source],
    });
    this.visible = false;
  }
}
