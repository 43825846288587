










































































import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import { Ace as AceEditor } from "vue2-brace-editor";

import { ConfigValueType } from "@/ab-tests/models/ConfigResponseModel";
import ValidUtil from "@/shared/utils/ValidUtil";
import JsonUtil from "@/ab-tests/utils/JsonUtil";

import "brace/mode/javascript";
import "brace/theme/monokai";
import "brace/theme/chrome";

@Component({
  components: {
    AceEditor,
  },
})
export default class ConfigResponseEditorValue extends Vue {
  @Prop() value!: string | number;
  @Prop({ default: ConfigValueType.STRING }) type!: ConfigValueType;
  @Prop() isEditable?: boolean;
  @Prop() isParentItemClicked?: boolean;

  ConfigValueType = ConfigValueType;
  localValue: any = "";
  defaultValue = "";
  key = 0;

  readonly booleanTypes = [
    {
      text: "true",
      value: true,
    },
    {
      text: "false",
      value: false,
    },
  ];

  get notValidList() {
    return (
      this.isArrayType &&
      !Array.isArray(JsonUtil.formattingFromJson(this.localValue))
    );
  }

  get notValidObject() {
    return (
      this.isObjectType &&
      JsonUtil.formattingFromJson(this.localValue).constructor !== Object
    );
  }

  get isStringType(): boolean {
    return this.type === ConfigValueType.STRING;
  }

  get isIntegerType(): boolean {
    return this.type === ConfigValueType.INTEGER;
  }

  get isArrayType(): boolean {
    return this.type === ConfigValueType.ARRAY;
  }

  get isObjectType(): boolean {
    return this.type === ConfigValueType.OBJECT;
  }

  get isFloatType(): boolean {
    return this.type === ConfigValueType.FLOAT;
  }

  get isBooleanType(): boolean {
    return this.type === ConfigValueType.BOOLEAN;
  }

  get rules() {
    if (this.isIntegerType) {
      return [
        ValidUtil.requiredNumber(this.$lang("validation.required")),
        ValidUtil.integer(this.$lang("validation.integer")),
      ];
    }

    if (this.isFloatType) {
      return [
        ValidUtil.requiredNumber(this.$lang("validation.required")),
        ValidUtil.maxForFloat(this.$lang("validation.maxFloat")),
      ];
    }

    return [ValidUtil.required(this.$lang("validation.required"))];
  }

  get aceEditorTheme(): string {
    return this.$vuetify.theme.dark ? "monokai" : "chrome";
  }

  @Watch("type", { immediate: true })
  private watchType() {
    this.localValue = "";
    // to rerender the ace editor
    this.key++;
  }

  @Watch("value", { immediate: true })
  private watchValue(value: string | number) {
    this.localValue = value;

    if (this.isEditable || this.isParentItemClicked) {
      if ((this.isObjectType || this.isArrayType) && value) {
        this.defaultValue = JSON.stringify(value, null, 2);
      } else if ((this.isObjectType || this.isArrayType) && !value) {
        this.defaultValue = "";
      } else {
        this.defaultValue = String(value);
      }
    } else {
      this.defaultValue = "";
    }
  }

  @Watch("localValue")
  @Emit("input")
  emitValue(value: string | number): string | number {
    return value;
  }

  onChange(value: string) {
    this.localValue = value;
  }

  onValidate(
    value: Array<{
      column: number;
      raw: string;
      row: number;
      text: string;
      type: string;
    }>
  ) {
    const hasError = value.some(
      (item) => item.type === "error" || item.type === "warning"
    );

    if (
      hasError ||
      !this.localValue ||
      this.notValidList ||
      this.notValidObject
    ) {
      this.$emit("onValidate", false);
    } else {
      this.$emit("onValidate", true);
    }
  }
}
