







































import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";

import ConfigResponseEditor from "@/ab-tests/components/ConfigResponseEditor.vue";
import AbTestResponseModel from "@/ab-tests/models/AbTestResponseModel";
import { AbTestControlGroupModel } from "@/ab-tests/models/AbTestConfigurationModel";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component({
  components: {
    ConfigResponseEditor,
  },
})
export default class AbTestConfigResponseItem extends Vue {
  @Prop() value!: AbTestResponseModel | AbTestControlGroupModel;
  @Prop({ default: () => [] }) responses!: Array<AbTestResponseModel>;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: 0 }) index!: number;
  @Prop({ default: false }) isControlGroup!: boolean;

  get rulesSegmentName() {
    return [
      ValidUtil.required(this.$lang("validation.required")),
      ValidUtil.maxLength(255, this.$lang("validation.maxLength", 255)),
      ValidUtil.restrict(
        AbTestResponseModel.REG_EXP_FOR_ANALYTICS_SEGMENT_NAME,
        this.$lang(
          "validation.restrict",
          AbTestResponseModel.RESTRICTED_SYMBOLS_FOR_ANALYTICS_SEGMENT_NAME
        )
      ),
      ValidUtil.notEndsWith(
        AbTestResponseModel.AB_TEST_CONTROL_SEGMENT_POSTFIX,
        this.$lang(
          "validation.notEndsWith",
          AbTestResponseModel.AB_TEST_CONTROL_SEGMENT_POSTFIX
        )
      ),
      ValidUtil.maxLength(50, this.$lang("validation.maxLength", 50)),
    ];
  }

  get segmentNameErrorMessages(): Array<string> {
    if (!this.readonly && !this.value.isUniqueSegmentName) {
      return [this.$lang("validation.unique")];
    }

    const isExists = this.responses.some(
      (response) =>
        response.key !== (this.value as AbTestResponseModel).key &&
        response.segmentName &&
        (this.value as AbTestResponseModel).segmentName &&
        response.segmentName === (this.value as AbTestResponseModel).segmentName
    );

    if (!this.readonly && isExists) {
      return [this.$lang("validation.exists")];
    }

    return [];
  }

  get controlGroupSegmentNameErrorMessages(): Array<string> {
    if (
      !this.readonly &&
      !(this.value as AbTestControlGroupModel).isUniqueSegmentName
    ) {
      return [this.$lang("validation.unique")];
    }

    const isExists = this.responses.some(
      (response) =>
        response.segmentName &&
        (this.value as AbTestControlGroupModel).segmentName &&
        response.segmentName ===
          (this.value as AbTestControlGroupModel).segmentName
    );

    if (!this.readonly && isExists) {
      return [this.$lang("validation.exists")];
    }

    return [];
  }

  @Watch("value.segmentName")
  private watchSegmentName(segmentName: string) {
    if (
      (this.value as AbTestResponseModel).segmentName !==
      segmentName.toLowerCase()
    ) {
      (this.value as AbTestResponseModel).segmentName =
        segmentName.toLowerCase();
    }

    if (!this.readonly) {
      this.emitChangedSegmentName(this.value);
    }
  }

  @Emit("changeSegmentName")
  emitChangedSegmentName(
    value: AbTestResponseModel | AbTestControlGroupModel
  ): {
    value: AbTestResponseModel | AbTestControlGroupModel;
    isControlGroup: boolean;
  } {
    return { value, isControlGroup: this.isControlGroup };
  }

  @Emit("deleteResponse")
  emitDeleteResponse() {
    return;
  }
}
