




























import { Component, Prop, Watch } from "vue-property-decorator";
import { mixins } from "vue-class-component";

import PickerDictionaryNotification from "@/shared/components/pickers/PickerDictionaryNotification.vue";
import DictionaryLoadMixin from "@/shared/mixins/DictionaryLoadMixin";
import { Dictionary, DictionaryType } from "@/shared/models";

@Component({ components: { PickerDictionaryNotification } })
export default class ComboboxRule extends mixins(DictionaryLoadMixin) {
  @Prop() value!: Array<string>;
  @Prop() disabled!: boolean;
  @Prop() dictionaryName!: DictionaryType;
  @Prop({ default: () => [] }) filtered!: Array<string>;

  localValue: Array<string> = [];
  filteredItems: Array<Dictionary> = [];
  dictionaryType = this.dictionaryName;

  get dictionaryData(): Array<Dictionary> {
    return this.$store.state.dictionaryStore[this.dictionaryType].values.filter(
      ({ value }: Dictionary) => !this.filtered.includes(value)
    );
  }

  @Watch("value", { immediate: true })
  watchValue(newValue: Array<string>) {
    this.localValue = newValue;
  }

  @Watch("localValue")
  watchValues(newValues: Array<string>, oldValues: Array<string> | undefined) {
    this.setUsedDictionaries(newValues, oldValues);
    this.$emit("input", newValues);
  }

  get items(): Array<Dictionary> {
    return this.dictionaryData.sort((a: Dictionary, b: Dictionary) => {
      const aSortText = (a.name ?? a.value).toLowerCase();
      const bSortText = (b.name ?? b.value).toLowerCase();
      const searchText = this.search?.toLowerCase();

      return aSortText.indexOf(searchText) - bSortText.indexOf(searchText);
    });
  }
}
