






































































































































import { Component, Vue, Prop, Emit } from "vue-property-decorator";

import ConfigResponseConfirmDialog from "@/ab-tests/components/ConfigResponseConfirmDialog.vue";
import ConfigImportMenu from "@/ab-tests/components/ConfigImportMenu.vue";
import ApplicationResponseModel from "@/ab-tests/models/ApplicationResponseModel";
import AbTestResponseModel from "@/ab-tests/models/AbTestResponseModel";
import ConfigUtil from "@/ab-tests/utils/ConfigUtil";

@Component({
  components: {
    ConfigResponseConfirmDialog,
    ConfigImportMenu,
  },
})
export default class ConfigResponseDeployer extends Vue {
  @Prop() value!: ApplicationResponseModel;
  @Prop({ default: "tab-stage" }) activeTab!: string;
  @Prop({ default: true }) hasDuplicateConfigs!: boolean;
  @Prop() editable!: boolean;
  @Prop({ default: () => [] }) abTestResponses!: Array<AbTestResponseModel>;
  @Prop({ default: () => [] }) previewItems!: Array<AbTestResponseModel>;
  @Prop({ default: false }) hasNonDeployedChanges!: boolean;
  @Prop({ default: false }) isDeploymentInProgress!: boolean;
  @Prop() hasDeployAccess!: boolean;

  confirmDialog = false;
  isCopyDialog = false;

  get dark() {
    return this.$vuetify.theme.dark;
  }

  get localAbTestResponses() {
    return this.abTestResponses.filter(
      (response: any) => response.id !== this.value.id
    );
  }

  get hintForDeployButton(): string {
    return this.isStageTab
      ? this.$lang("commonConfig.deployToStageHint")
      : this.$lang("commonConfig.deployToProdHint");
  }

  get hintForTransferButton(): string {
    return this.isStageTab
      ? this.$lang("commonConfig.transferToProdHint")
      : this.$lang("commonConfig.transferToStageHint");
  }

  get iconForTransferButton(): string {
    return this.isStageTab ? "mdi-transfer-right" : "mdi-transfer-left";
  }

  get isStageTab(): boolean {
    return this.activeTab === "tab-stage";
  }

  get hasDiffStageAndProd(): boolean {
    return ConfigUtil.hasDiffBetweenConfigs(
      this.value.stage.response,
      this.value.production.response
    );
  }

  get isAbTestConfigPage(): boolean {
    return this.$route.name === "abTest_edit_configs";
  }

  @Emit("copyResponse") emitCopyResponse(
    payload: AbTestResponseModel,
    field: string
  ) {
    this.isCopyDialog = false;

    return {
      payload,
      field,
    };
  }
}
