














import { Component, Vue, Ref, Prop, Watch } from "vue-property-decorator";
import * as ace from "brace";

import AceUtil from "@/ab-tests/utils/AceUtil";

@Component
export default class ConfigResponseDialog extends Vue {
  @Ref("el") readonly elContainer!: HTMLElement;
  @Prop() value!: string;
  @Prop() parent!: Array<string>;

  jsonView!: ace.Editor;
  dialog = false;

  get dark() {
    return this.$vuetify.theme.dark;
  }

  @Watch("value")
  private watchValue(value: string) {
    if (!this.jsonView) return;

    const values = this.parent ? this.parent.slice() : [];
    if (value) {
      values.push(value);
    }
    AceUtil.setValue(this.jsonView, values);
  }

  @Watch("dark")
  private watchDark(dark: boolean) {
    if (!this.jsonView) return;

    AceUtil.setTheme(this.jsonView, dark);
  }

  mounted() {
    this.$on("openJsonDialog", (val: boolean) => {
      this.dialog = val;
    });
  }

  updated() {
    this.jsonView = AceUtil.init(this.elContainer, this.dark);
    this.jsonView.setReadOnly(true);

    this.watchValue(this.value);
  }
}
