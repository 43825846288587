






















import { Component, Vue, Prop, Emit } from "vue-property-decorator";

@Component
export default class SaveConfirmDialog extends Vue {
  @Prop({ default: false }) value!: boolean;

  @Emit("back") emitBack() {
    return;
  }

  @Emit("save") emitSave() {
    return;
  }
}
