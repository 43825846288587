



































































































import { isEqual } from "lodash";
import moment from "moment";
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import RulesDaysInterval from "@/ab-tests/components/RulesDaysInterval.vue";
import { AbTestType } from "@/ab-tests/models/AbTestConfigurationModel";
import {
  DateRangeConditionModel,
  DateRangeConditionType,
  RuleOperator,
} from "@/ab-tests/models/RuleModel";

@Component({
  components: {
    RulesDaysInterval,
  },
})
export default class RulesInstallDatesPicker extends Vue {
  @Prop() value!: DateRangeConditionModel | null;
  @Prop() testType!: AbTestType;
  @Prop() maxInstallDate?: string;
  @Prop() operator!: RuleOperator;
  @Ref() menu!: any;

  readonly DateRangeConditionType = DateRangeConditionType;

  localDateRange = new DateRangeConditionModel();
  dates: Array<string> = [];
  menuVisible = false;

  get isAudienceTestType(): boolean {
    return this.testType === AbTestType.AUDIENCE;
  }

  get isAbsoluteInstallDates(): boolean {
    return this.localDateRange.type === DateRangeConditionType.ABSOLUTE_VALUE;
  }

  get localValueText(): string | null {
    if (this.dates.length === 2 && this.dates[0] === this.dates[1]) {
      return this.dates[0];
    }

    if (this.dates.length) {
      return this.dates.join(" - ");
    }

    return null;
  }

  set localValueText(value: string | null) {
    if (!value) {
      this.clearDates();
    }
  }

  get installDatesErrorMessages(): Array<string> {
    if (this.isAudienceTestType && this.isAbsoluteInstallDates) {
      if (this.maxInstallDate) {
        const hasDateGreaterThanMaxDate = this.dates.some((item) =>
          moment(item).isAfter(moment(this.maxInstallDate))
        );

        if (hasDateGreaterThanMaxDate) {
          return [this.$lang("validation.dateGreaterThanMaxDate")];
        }
      }

      if (!this.dates.length && this.operator === RuleOperator.INCLUDE) {
        return [this.$lang("validation.required")];
      }
    }

    return [];
  }

  @Watch("value", { immediate: true })
  watchValue() {
    if (isEqual(this.value, this.localDateRange)) {
      return;
    }

    this.localDateRange = this.value
      ? DateRangeConditionModel.of(this.value)
      : new DateRangeConditionModel();
  }

  @Watch("localDateRange", { deep: true, immediate: true })
  updateInstallDates(newValue: DateRangeConditionModel) {
    this.dates = [
      ...(newValue.minInstallDate ? [newValue.minInstallDate] : []),
      ...(newValue.maxInstallDate ? [newValue.maxInstallDate] : []),
    ];
    this.$emit("input", newValue);
  }

  @Watch("dates")
  watchDates(dates: Array<string>) {
    if (dates.length === 2 && dates[0] > dates[1]) {
      dates.sort();
    }
  }

  saveDates() {
    this.localDateRange.minInstallDate = this.dates[0];
    this.localDateRange.maxInstallDate = this.dates[1] ?? this.dates[0];
    this.menu.save(this.dates);
    this.menuVisible = false;
  }

  clearDates() {
    this.dates = [];
    this.saveDates();
  }
}
