var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-menu',{attrs:{"close-on-content-click":false,"max-width":"360","min-width":"360","bottom":"","left":"","offset-y":""},on:{"input":function($event){_vm.search = null}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onHover = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-3",attrs:{"disabled":!_vm.hasAccessForTargetConfigs,"fab":"","x-small":""}},Object.assign({}, on, onHover)),[_c('v-icon',[_vm._v("mdi-import")])],1)]}}],null,true)},[_vm._v(" "+_vm._s(_vm.$lang("configResponseEditor.copyConfigFrom"))+" ")])]}}]),model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('v-sheet',[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-text-field',{attrs:{"label":_vm.$lang('configResponseEditor.copyConfigFrom'),"append-icon":"search","hide-details":"","clearable":"","autofocus":"","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"search"}}),_c('v-btn-toggle',{staticClass:"ml-1 mr-n1",attrs:{"color":"primary","group":"","dense":"","mandatory":""},model:{value:(_vm.source),callback:function ($$v) {_vm.source=$$v},expression:"source"}},[_c('v-btn',{attrs:{"value":_vm.EnvironmentType.STAGE,"small":""}},[_vm._v(_vm._s(_vm.$lang("configResponseEditor.stage")))]),_c('v-btn',{attrs:{"value":_vm.EnvironmentType.PROD,"small":""}},[_vm._v(_vm._s(_vm.$lang("configResponseEditor.prod")))])],1)],1),(_vm.searchInLowerCase.length < _vm.MIN_SEARCH_SYMBOLS)?_c('div',{staticClass:"px-4 pb-4 pt-0 text-caption"},[_vm._v(" "+_vm._s(_vm.$lang("configResponseEditor.enterSomeSymbols", _vm.MIN_SEARCH_SYMBOLS))+" ")]):(_vm.abTests.length || _vm.targetedConfigs.length)?_c('v-list',{staticClass:"overflow-y-auto pt-0",attrs:{"max-height":450,"dense":"","nav":""}},[(_vm.targetedConfigs.length)?[_c('v-divider'),_c('div',{staticClass:"text-button px-2"},[_vm._v(" "+_vm._s(_vm.$lang("configResponseEditor.targetedConfigs"))+" ")]),_c('v-divider',{staticClass:"mb-1"}),_vm._l((_vm.targetedConfigs),function(ref){
var name = ref.name;
var response = ref.response;
return _c('v-list-item',{key:name,attrs:{"disabled":response.id === _vm.responseId,"link":""},on:{"click":function($event){return _vm.copyResponse(response)}}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('div',{staticClass:"d-flex flex-grow-1"},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(name))])],1),_c('v-fade-transition',[_c('v-list-item-action',{directives:[{name:"show",rawName:"v-show",value:(hover),expression:"hover"}],staticClass:"my-2"},[_c('v-icon',[_vm._v("mdi-content-copy")])],1)],1)],1)]}}],null,true)})],1)}),_c('v-divider'),_c('div',{staticClass:"d-flex align-baseline px-2"},[_c('span',{staticClass:"text-button"},[_vm._v(_vm._s(_vm.$lang("configResponseEditor.abTests")))]),_c('span',{staticClass:"text-caption ml-3 red--text"},[_vm._v(_vm._s(_vm.$lang("configResponseEditor.notSupportedYet")))])]),_c('v-divider',{staticClass:"mb-1"})]:_vm._e()],2):_c('div',{staticClass:"px-4 pb-4 pt-0 text-caption"},[_vm._v(" "+_vm._s(_vm.$lang("configResponseEditor.nothingFound"))+" ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }