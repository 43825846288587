var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"persistent":"","width":"800"},model:{value:(_vm.value),callback:function ($$v) {_vm.value=$$v},expression:"value"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.$lang("configResponseEditor.confirmDialogTitle"))+" ")]),_c('v-card-text',[(_vm.isDefaultConfigPage)?_c('v-row',[_c('v-col',{staticClass:"pb-1",attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"mb-0 px-2 py-1",attrs:{"type":"info","outlined":"","dense":""}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$lang('configResponseEditor.alertInfoText'))}})])],1)],1):_vm._e(),_c('v-row',_vm._l((_vm.infoItems),function(infoItem){return _c('v-col',{key:infoItem.id,staticClass:"d-flex align-center",attrs:{"cols":"auto"}},[_c('div',{staticClass:"mr-2",style:([
              {
                background: infoItem.color,
                width: '14px',
                height: '14px',
                borderRadius: '50%',
              } ])}),_vm._v(" "+_vm._s(infoItem.text)+" ")])}),1),_c('v-data-table',{staticClass:"elevation-0 custom-table",attrs:{"headers":_vm.headers,"items":_vm.items,"height":_vm.heightForTable,"hide-default-footer":"","fixed-header":"","disable-pagination":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
            var item = ref.item;
return [_c('tr',{style:({
              backgroundColor: _vm.infoColors[item.type],
            })},[_c('td',[_vm._v(" "+_vm._s(item.key)+" ")]),_c('td',{staticStyle:{"max-width":"300px","text-overflow":"ellipsis","overflow":"hidden","white-space":"nowrap"}},[_c('span',{attrs:{"title":_vm.getItemValue(item)}},[_vm._v(" "+_vm._s(_vm.getItemValue(item))+" ")])])])]}}])})],1),_c('v-card-actions',[_c('v-checkbox',{staticClass:"mr-2",attrs:{"label":_vm.labelForCheckbox},model:{value:(_vm.deployAndSync),callback:function ($$v) {_vm.deployAndSync=$$v},expression:"deployAndSync"}}),_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$emit('input', false)}}},[_vm._v(" "+_vm._s(_vm.$lang("btn.cancel"))+" ")]),_c('v-btn',{attrs:{"text":"","color":"primary","disabled":!_vm.hasDeployAccess},on:{"click":_vm.handleDeploy}},[_vm._v(" "+_vm._s(_vm.textForDeployButton)+" ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }