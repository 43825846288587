






































































































import { Component, Vue, Prop } from "vue-property-decorator";

import ItemPreviewModel, {
  PREVIEW_ITEM_TYPE,
} from "@/ab-tests/models/ItemPreviewModel";
import { AppSection, CurrentUserModel } from "@/shared/models";

@Component
export default class ConfigResponseConfirmDialog extends Vue {
  @Prop({ default: false }) value!: boolean;
  @Prop({ default: () => [] }) previewItems!: Array<ItemPreviewModel>;
  @Prop({ default: true }) isStageTab!: boolean;
  @Prop() hasDeployAccess!: boolean;

  readonly infoColors: Record<PREVIEW_ITEM_TYPE, string> = {
    ADDED: "#4CAF5044",
    EDITED: "#FFEB3B44",
    DELETED: "#F4433644",
  };
  readonly infoItems = [
    {
      id: 1,
      text: this.$lang("configResponseEditor.addedConfigItem"),
      color: this.infoColors.ADDED,
    },
    {
      id: 2,
      text: this.$lang("configResponseEditor.editedConfigItem"),
      color: this.infoColors.EDITED,
    },
    {
      id: 3,
      text: this.$lang("configResponseEditor.deletedConfigItem"),
      color: this.infoColors.DELETED,
    },
  ];
  readonly headers = [
    {
      text: this.$lang("jsonEditor.key"),
      align: "start",
      value: "key",
      sortable: false,
    },
    {
      text: this.$lang("jsonEditor.value"),
      value: "value",
      sortable: false,
    },
  ];
  dialog = false;
  deployAndSync = false;

  get labelForCheckbox(): string {
    return this.$lang(
      "commonConfig.deployAndSync",
      this.isStageTab
        ? this.$lang("commonConfig.production")
        : this.$lang("commonConfig.stage")
    );
  }

  get textForDeployButton(): string {
    return this.isStageTab
      ? this.$lang("commonConfig.deployToStageHint")
      : this.$lang("commonConfig.deployToProdHint");
  }

  get heightForTable(): string {
    return this.items.length > 13 ? "672" : "";
  }

  get types(): Record<PREVIEW_ITEM_TYPE, number> {
    return Object.keys(PREVIEW_ITEM_TYPE).reduce(
      (
        result: Record<PREVIEW_ITEM_TYPE, number>,
        type: string,
        index: number
      ) => {
        return {
          ...result,
          [type]: index + 1,
        };
      },
      {} as Record<PREVIEW_ITEM_TYPE, number>
    );
  }

  get items(): Array<ItemPreviewModel> {
    return this.previewItems.sort(
      (a, b) => this.types[a.type] - this.types[b.type]
    );
  }

  get currentUser(): CurrentUserModel {
    return this.$store.state.userStore.currentUser;
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get isDefaultConfigPage(): boolean {
    return this.$route.name === AppSection.DEFAULT_CONFIG;
  }

  get isAbTestConfigPage(): boolean {
    return this.$route.name === "abTest_edit_configs";
  }

  get isTargetedConfigPage(): boolean {
    return this.$route.name === "targeted_edit_configs";
  }

  getItemValue(item: ItemPreviewModel): string {
    return typeof item.value === "string"
      ? JSON.parse(JSON.stringify(item.value))
      : JSON.stringify(item.value);
  }

  handleDeploy() {
    this.$emit("deploy", this.deployAndSync);
    this.$emit("input", false);
  }
}
