import * as ace from "brace";
import "brace/mode/json";
import "brace/theme/tomorrow";
import "brace/theme/tomorrow_night";
import "brace/ext/searchbox";

import JsonUtil from "./JsonUtil";

export default class AceUtil {
  static init(el: HTMLElement, dark: boolean) {
    const result = ace.edit(el);
    AceUtil.setTheme(result, dark);
    result.setOptions({
      autoScrollEditorIntoView: true,
    });
    result.getSession().setMode("ace/mode/json");
    result.getSession().setTabSize(2);

    return result;
  }

  static setTheme(editor: ace.Editor, dark: boolean) {
    editor.setTheme(dark ? "ace/theme/tomorrow_night" : "ace/theme/tomorrow");
  }

  static setValue(
    editor: ace.Editor,
    val: Array<string>,
    cursorPosition?: ace.Position
  ) {
    if (val && val.length > 0) {
      editor.setValue(JsonUtil.merge(val), -1);
      if (cursorPosition) {
        editor.gotoLine(cursorPosition.row + 1, cursorPosition.column);
      }
    } else {
      editor.setValue("", -1);
    }
  }

  static find(editor: ace.Editor, val: string) {
    if (!val) {
      return;
    }

    editor.find(val.substr(val.charAt(0) === "{" ? 1 : 0));
  }
}
