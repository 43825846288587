




































































































































































































import { debounce } from "lodash";
import moment from "moment";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import AbTestConfigurationModel, {
  AbTestMetricType,
  AbTestType,
} from "@/ab-tests/models/AbTestConfigurationModel";
import ConfigUtil from "@/ab-tests/utils/ConfigUtil";
import { Dictionary, DictionaryType } from "@/shared/models";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component
export default class AbTestConfigSettings extends Vue {
  @Prop() settings!: AbTestConfigurationModel;
  @Prop() minDate?: string;
  @Prop({ default: () => [] }) existingNames!: Array<string>;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: false }) editable!: boolean;
  @Prop({ default: true }) hasWritePermission!: boolean;

  menuAssignmentTill = false;
  menuActiveSince = false;
  menuActiveTill = false;

  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly requiredArrayRule = [
    ValidUtil.requiredArray(this.$lang("validation.required")),
  ];
  readonly abTestTypes = Object.values(AbTestType).map((value: string) => ({
    text: this.$lang(`abTestConfig.abTestType.${value.toLowerCase()}`),
    value,
  }));

  get appId(): string {
    return this.$route.params.id;
  }

  get minUsersCount(): number {
    return this.$store.state.abTestConfig.minUsersCount;
  }

  get rulesName() {
    return [
      ValidUtil.required(this.$lang("validation.required")),
      ValidUtil.maxLength(255, this.$lang("validation.maxLength", 255)),
      ValidUtil.unique(this.existingNames, this.$lang("validation.unique")),
    ];
  }

  get rulesHypothesis() {
    return [
      ValidUtil.required(this.$lang("validation.required")),
      ValidUtil.maxLength(200, this.$lang("validation.maxLength", 200)),
      ValidUtil.minLength(10, this.$lang("validation.minLength", 10)),
    ];
  }

  get rulesActiveTill() {
    return [
      (val: string) =>
        !val ||
        !(
          this.settings.assignmentTillDate &&
          val > this.settings.assignmentTillDate
        ) ||
        this.$lang("abTestConfig.error.assignmentTill"),
      ValidUtil.minDate(
        this.minDate || this.settings.activeSince,
        this.$lang(
          "validation.min",
          this.minDate || this.settings.activeSince || ""
        )
      ),
    ];
  }

  get rulesActiveSince() {
    return [
      ValidUtil.required(this.$lang("validation.required")),
      ValidUtil.maxDate(
        this.settings.activeTill,
        this.$lang("validation.min", this.settings.activeTill || "")
      ),
      ValidUtil.minDate(
        ConfigUtil.getCurrentDate(),
        this.$lang("validation.min", ConfigUtil.getCurrentDate())
      ),
      ValidUtil.minDate(
        this.minDate,
        this.$lang("validation.min", this.minDate || "")
      ),
    ];
  }

  get ruleAssignmentTillUsersCount(): Array<any> {
    return [
      ValidUtil.required(this.$lang("validation.required")),
      ValidUtil.integer(this.$lang("validation.integer")),
      ValidUtil.min(
        this.minUsersCount,
        this.$lang("validation.min", this.minUsersCount)
      ),
    ];
  }

  get minActiveSince(): string {
    return !!this.minDate && this.minDate > this.currentDate
      ? this.minDate
      : this.currentDate;
  }

  get minActiveTill(): string {
    let dates = [];
    if (this.minDate) {
      dates.push(this.minDate);
    }
    if (this.settings.activeSince) {
      dates.push(this.settings.activeSince);
    }
    dates = dates.sort().reverse();
    return dates.length > 0 && dates[0] > this.currentDate
      ? dates[0]
      : this.currentDate;
  }

  get minAssignmentTill(): string {
    return (
      this.settings.activeTill ? moment(this.settings.activeTill) : moment()
    )
      .add(1, "days")
      .toISOString();
  }

  get currentDate(): string {
    return ConfigUtil.getCurrentDate();
  }

  get isAbTypeNewUsers(): boolean {
    return this.settings.abTestType === AbTestType.NEW_USERS;
  }

  get isAbTypeAudience(): boolean {
    // попросили пока что закоментить
    // return this.settings.abTestType === AbTestType.AUDIENCE;
    return false;
  }

  get dayNumbers(): Array<any> {
    return this.$store.state.dictionaryStore[
      DictionaryType.AB_TEST_DAY_NUMBER
    ].values.map(({ value }: Dictionary) => Number(value));
  }

  get abTestMetricTypes(): Array<Record<string, string>> {
    return Object.values(AbTestMetricType).map((value: string) => ({
      text: this.$lang(`abTestConfig.metricType.${value.toLowerCase()}`),
      value,
    }));
  }

  get isAbTestNameUnique(): boolean {
    return this.$store.state.abTestConfig.isAbTestNameUnique;
  }

  get nameErrorMessages(): Array<string> {
    if (!this.readonly && !this.isAbTestNameUnique) {
      return [this.$lang("shared.errors.notUnique")];
    }
    if (this.settings.name && this.settings.name.length > 50) {
      return [this.$lang("shared.errors.maxCharacters", 50)];
    }

    return [];
  }

  @Watch("settings.name", { immediate: true })
  watchName = debounce(() => {
    if (this.readonly) {
      return;
    }

    this.$store.dispatch("checkIsAbTestNameUnique", {
      appId: this.appId,
      abTest: this.settings,
    });
  }, 500);
}
