






































import { Component, Vue, Prop } from "vue-property-decorator";
import { AxiosResponse } from "axios";

import CsvFileView from "./CsvFileView.vue";
import ValidUtil from "@/shared/utils/ValidUtil";

interface CsvFileResponse {
  id?: number;
}

@Component({
  components: { CsvFileView },
})
export default class CsvFileInput extends Vue {
  @Prop() csvFileId?: number;
  @Prop() disabled?: boolean;

  private csvFile: File | null = null;
  private loading = false;
  private showTooltip = false;

  get rulesValid() {
    return [ValidUtil.required(this.$lang("validation.required"))];
  }

  get appId() {
    return this.$store.state.application.applicationId;
  }

  private handleFilesUpload() {
    const formData = new FormData();
    if (!this.csvFile) {
      this.$store.dispatch("addError", this.$lang("error.noCsvFileChosen"));
      return;
    }
    formData.append("file", this.csvFile, this.csvFile.name);

    const processResponse = (result: AxiosResponse<CsvFileResponse>) => {
      this.$emit("changeCsvFileId", result.data.id);
      this.init();
    };
    const processError = () => this.init();

    this.loading = true;
    this.axios
      .post(`/api/ab-tests/app/${this.appId}/csv/files`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then(processResponse)
      .catch(processError);
  }

  private init() {
    this.csvFile = null;
    this.loading = false;
  }

  private clear() {
    this.$emit("changeCsvFileId", null);
  }
}
