
































































































































































































































































import { Component, Emit, Prop, Vue, Watch } from "vue-property-decorator";

import RulesDialog from "@/ab-tests/components/RulesDialog.vue";
import CsvFileView from "@/ab-tests/components/CsvFileView.vue";

import AbTestConfigurationModel, {
  AbTestType,
} from "@/ab-tests/models/AbTestConfigurationModel";
import RuleModel, {
  DateRangeConditionModel,
  DateRangeConditionType,
  RuleOperator,
  RuleType,
} from "@/ab-tests/models/RuleModel";
import { Dictionary, DictionaryType } from "@/shared/models";

@Component({
  components: {
    RulesDialog,
    CsvFileView,
  },
})
export default class Rules extends Vue {
  @Prop({ default: () => [] }) value!: Array<RuleModel>;
  @Prop() settings!: AbTestConfigurationModel;
  @Prop() maxInstallDate?: string;
  @Prop({ default: () => [] }) filteredSegments!: Array<string>;
  @Prop({ default: false }) readonly!: boolean;
  @Prop() abTestType!: AbTestType;
  @Prop({ default: true }) hasAffectedAuditory!: boolean;

  dialogRule = false;
  editedIndex = -1;
  editedItem: RuleModel | null = null;
  localRules: Array<RuleModel> = [];

  readonly RuleType = RuleType;

  get samplingRate(): number {
    return this.settings.samplingRate ?? 1;
  }

  set samplingRate(val: number) {
    this.settings.samplingRate = val;
  }

  get simpleRateItems(): Array<{ value: number; percent: number }> {
    const simpleRateItems = [];

    for (let i = 1; i <= 20; i++) {
      simpleRateItems.push({
        value: i,
        percent: Number((100 / i).toFixed(2)),
      });
    }

    return simpleRateItems;
  }

  get appId(): string {
    return this.$store.state.application.applicationId;
  }

  get backgroundForBorder(): any {
    return (item: RuleModel): string =>
      item.operator === RuleOperator.EXCLUDE
        ? "red lighten-1"
        : "green lighten-1";
  }

  get sortedRules(): Record<string, Array<RuleModel>> {
    return this.localRules.reduce(
      (result: any, rule: RuleModel, index: number) => {
        result[rule.operator] = result[rule.operator] || [];
        result[rule.operator].push({ ...rule, index });

        return result;
      },
      {}
    );
  }

  @Watch("value", { immediate: true })
  watchValue() {
    this.localRules = this.value.map((rule) => RuleModel.of(rule));
  }

  compareIncludeRuleWithExcludeRule(item: any): RuleModel | null {
    return this.sortedRules[RuleOperator.EXCLUDE]
      ? this.sortedRules[RuleOperator.EXCLUDE].filter((rule: any) => {
          return Object.keys(item).some((key) => {
            if (
              Array.isArray(rule[key]) &&
              rule[key].length &&
              Array.isArray(item[key]) &&
              item[key].length &&
              item[key]?.length === rule[key].length
            ) {
              return rule[key].every(
                (value: any, index: number) => value === item[key][index]
              );
            } else if (
              rule[key] &&
              item[key] &&
              key !== "operator" &&
              key !== "type" &&
              key !== "index"
            ) {
              return rule[key] === item[key];
            }
          });
        })[0]
      : null;
  }

  getIndexForRule(operator: RuleOperator, index: number): number {
    return operator === RuleOperator.EXCLUDE
      ? index
      : this.sortedRules[RuleOperator.EXCLUDE]
      ? index + this.sortedRules[RuleOperator.EXCLUDE].length
      : index;
  }

  addRule() {
    this.editedItem = null;
    this.editedIndex = -1;
    this.dialogRule = true;
  }

  editRule(index: number) {
    this.editedIndex = index;
    this.editedItem = RuleModel.of(this.localRules[index]);
    this.dialogRule = true;
  }

  save(rule: RuleModel) {
    if (this.editedItem) {
      this.localRules.splice(this.editedIndex, 1, RuleModel.of(rule));
    } else if (rule.operator === RuleOperator.INCLUDE) {
      this.localRules.push(RuleModel.of(rule));
    } else {
      this.localRules.unshift(RuleModel.of(rule));
    }

    this.emitChangedRules();
    this.close();
  }

  deleteRule(index: number) {
    this.localRules.splice(index, 1);
    this.emitChangedRules();
  }

  close() {
    this.dialogRule = false;
    this.$emit("checkValidation");
  }

  formatDictionaryValues(values: Array<string>, dictionaryName: string) {
    const dictionary: Array<Dictionary> =
      this.$store.state.dictionaryStore[dictionaryName].values;
    const res = values
      .map(
        (value) =>
          dictionary.find(
            (it) => value.toLowerCase() === it.value.toLowerCase()
          )?.name
      )
      .join(", ");

    return res;
  }

  getDeviceModels(values: Array<string>) {
    return this.$store.state.dictionaryStore[
      DictionaryType.DEVICE_MODELS
    ].values.reduce((result: string, { name, value }: Dictionary) => {
      if (values.includes(value)) {
        result += result ? ", " : "";
        result += name || value;
      }

      return result;
    }, "");
  }

  getInstallDatesPreview({
    type,
    minInstallDate,
    maxInstallDate,
    operator,
    values,
  }: DateRangeConditionModel): string {
    return {
      [DateRangeConditionType.ABSOLUTE_VALUE]: () =>
        minInstallDate === maxInstallDate
          ? (minInstallDate as string)
          : `${minInstallDate} — ${maxInstallDate}`,
      [DateRangeConditionType.USERS_AGE]: () =>
        this.$lang(`rules.usersAge.${operator.toLowerCase()}`, ...values),
    }[type]();
  }

  @Emit("input") emitChangedRules() {
    return this.localRules;
  }
}
