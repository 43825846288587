



















































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import {
  DateRangeConditionModel,
  DateRangeConditionOperator,
} from "@/ab-tests/models/RuleModel";
import ValidUtil from "@/shared/utils/ValidUtil";

@Component
export default class RulesDaysInterval extends Vue {
  @Prop() range!: DateRangeConditionModel;

  localRange = DateRangeConditionModel.of(this.range);

  get dateRangeOperators(): Array<Record<string, string>> {
    return Object.values(DateRangeConditionOperator).map((value) => ({
      text: this.$lang(`rules.dateRangeOperator.${value.toLowerCase()}`),
      value,
    }));
  }

  get hasSecondValue(): boolean {
    return this.localRange.operator === DateRangeConditionOperator.BETWEEN;
  }

  get max(): number | undefined {
    return this.localRange.values[1] ?? undefined;
  }

  get min(): number {
    return this.localRange.values[0] ?? 0;
  }

  get fromRules(): Array<any> {
    return [
      ValidUtil.requiredNumber(this.$lang("validation.required")),
      ...(this.max !== undefined
        ? [ValidUtil.max(this.max, this.$lang("validation.max", this.max))]
        : []),
    ];
  }

  get toRules(): Array<any> {
    return [
      ValidUtil.requiredNumber(this.$lang("validation.required")),
      ...(this.min !== undefined
        ? [ValidUtil.min(this.min, this.$lang("validation.min", this.min))]
        : []),
    ];
  }

  @Watch("localRange", { deep: true })
  updateRange(newRange: DateRangeConditionModel) {
    if (newRange.operator !== this.range.operator) {
      this.localRange.values.length = 0;
    }

    this.$emit("update:range", DateRangeConditionModel.of(this.localRange));
  }
}
