















































































































































































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";

import ComboboxRule from "@/ab-tests/components/ComboboxRule.vue";
import CsvFileInput from "@/ab-tests/components/CsvFileInput.vue";
import CsvFileView from "@/ab-tests/components/CsvFileView.vue";
import RulesInstallDatesPicker from "@/ab-tests/components/RulesInstallDatesPicker.vue";
import Lat from "@/shared/components/Lat.vue";

import { AbTestType } from "@/ab-tests/models/AbTestConfigurationModel";
import RuleModel, {
  RuleCustomPropertyModel,
  RuleOperator,
  RuleType,
} from "@/ab-tests/models/RuleModel";
import { DictionaryType } from "@/shared/models";

@Component({
  components: {
    CsvFileInput,
    CsvFileView,
    ComboboxRule,
    Lat,
    RulesInstallDatesPicker,
  },
})
export default class RulesDialog extends Vue {
  @Prop() value!: boolean;
  @Prop({ default: null }) rule!: RuleModel | null;
  @Prop() abTestType!: AbTestType;
  @Prop() filteredSegments!: Array<string>;
  @Prop() maxInstallDate?: string;

  readonly DictionaryType = DictionaryType;

  localRule = new RuleModel();
  customProperties: Array<RuleCustomPropertyModel> = [];

  get visible(): boolean {
    return this.value;
  }

  set visible(newValue: boolean) {
    this.$emit("input", newValue);
  }

  get title(): string {
    return this.$lang(this.rule ? "rules.new" : "rules.edit");
  }

  get isCustomPropertiesValid(): boolean {
    const uniqueKeys = new Set(this.customProperties.map(({ key }) => key));

    return (
      !this.customProperties.length ||
      (this.customProperties.every(({ key, value }) => key && value) &&
        uniqueKeys.size === this.customProperties.length)
    );
  }

  get isFileForm(): boolean {
    return this.localRule.type === RuleType.UserId;
  }

  get isSaveDisabled(): boolean {
    return {
      [RuleType.UserId]: () => !this.localRule.csvFileId,
      [RuleType.UserProperty]: () =>
        !this.isCustomPropertiesValid ||
        !(
          this.localRule.languages?.length ||
          this.localRule.countries?.length ||
          this.localRule.deviceTypes?.length ||
          this.localRule.deviceModels?.length ||
          this.localRule.appVersions?.length ||
          this.localRule.osVersions?.length ||
          this.localRule.segments?.length ||
          this.localRule.isLatEnabled !== null ||
          this.localRule.installDates?.valid ||
          this.customProperties.length
        ) ||
        (this.localRule.operator === RuleOperator.INCLUDE &&
          this.abTestType === AbTestType.AUDIENCE &&
          !this.localRule.installDates?.valid),
    }[this.localRule.type]();
  }

  get isUserChangeable(): boolean {
    return this.abTestType !== AbTestType.NEW_USERS;
  }

  @Watch("value", { immediate: true })
  watchValue() {
    if (!this.value) {
      return;
    }

    this.localRule = this.rule ? RuleModel.of(this.rule) : new RuleModel();
    this.customProperties = Object.keys(this.localRule.customProperties).map(
      (key) =>
        new RuleCustomPropertyModel(key, this.localRule.customProperties[key])
    );
  }

  save() {
    this.localRule.customProperties = Object.fromEntries(
      this.customProperties.map(({ key, value }) => [key, value])
    );
    this.$emit("save", this.localRule);
  }

  setCsvFileId(val: number) {
    this.localRule.csvFileId = val;
  }

  addCustomProperty() {
    this.customProperties.push(new RuleCustomPropertyModel());
  }

  removeCustomProperty(deletedId: string) {
    this.customProperties.splice(
      this.customProperties.findIndex(({ id }) => id === deletedId),
      1
    );
  }
}
