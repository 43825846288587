var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('v-text-field',{attrs:{"readonly":_vm.readonly,"rules":_vm.rulesName,"error-messages":_vm.nameErrorMessages,"label":_vm.$lang('commonConfig.name'),"atid":"rule_name_field"},model:{value:(_vm.settings.name),callback:function ($$v) {_vm.$set(_vm.settings, "name", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"settings.name"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"readonly":_vm.readonly,"rules":_vm.rulesHypothesis,"label":_vm.$lang('commonConfig.hypothesis')},model:{value:(_vm.settings.hypothesis),callback:function ($$v) {_vm.$set(_vm.settings, "hypothesis", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"settings.hypothesis"}})],1),_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('v-select',{attrs:{"readonly":_vm.readonly,"items":_vm.abTestTypes,"label":_vm.$lang('abTestConfig.abTestTypeTitle'),"rules":_vm.requiredRule},model:{value:(_vm.settings.abTestType),callback:function ($$v) {_vm.$set(_vm.settings, "abTestType", $$v)},expression:"settings.abTestType"}})],1),(_vm.settings.abTestType)?[_c('v-col',{attrs:{"sm":"12","md":"6","atid":"active_since_view"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$lang('commonConfig.activeSince'),"rules":_vm.rulesActiveSince,"persistent-hint":"","readonly":"","prepend-icon":"event","atid":"active_since_field"},model:{value:(_vm.settings.activeSince),callback:function ($$v) {_vm.$set(_vm.settings, "activeSince", $$v)},expression:"settings.activeSince"}},
                    _vm.readonly && !_vm.editable ? null : Object.assign({}, onMenu, onTooltip)
                  ))]}}],null,true)},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.$lang("commonConfig.inputActiveSinceHint")))])])]}}],null,false,2814952673),model:{value:(_vm.menuActiveSince),callback:function ($$v) {_vm.menuActiveSince=$$v},expression:"menuActiveSince"}},[_c('v-date-picker',{attrs:{"min":_vm.minActiveSince,"max":_vm.settings.activeTill,"first-day-of-week":1,"no-title":"","readonly":!_vm.hasWritePermission,"atid":"date_picker_active_since"},on:{"input":function($event){_vm.menuActiveSince = false}},model:{value:(_vm.settings.activeSince),callback:function ($$v) {_vm.$set(_vm.settings, "activeSince", $$v)},expression:"settings.activeSince"}})],1)],1),(_vm.isAbTypeAudience)?_c('v-col',{attrs:{"sm":"12","md":"6","atid":"active_till_view"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var onTooltip = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$lang('commonConfig.activeTill'),"rules":_vm.rulesActiveTill,"persistent-hint":"","readonly":"","prepend-icon":"event","atid":"active_till_field"},model:{value:(_vm.settings.activeTill),callback:function ($$v) {_vm.$set(_vm.settings, "activeTill", $$v)},expression:"settings.activeTill"}},
                    _vm.readonly && !_vm.editable ? null : Object.assign({}, onMenu, onTooltip)
                  ))]}}],null,true)},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.$lang("commonConfig.inputActiveTillHint")))])])]}}],null,false,1638655598),model:{value:(_vm.menuActiveTill),callback:function ($$v) {_vm.menuActiveTill=$$v},expression:"menuActiveTill"}},[_c('v-date-picker',{attrs:{"min":_vm.minActiveTill,"first-day-of-week":1,"no-title":"","readonly":_vm.readonly,"atid":"date_picker_active_till"},on:{"input":function($event){_vm.menuActiveTill = false}},model:{value:(_vm.settings.activeTill),callback:function ($$v) {_vm.$set(_vm.settings, "activeTill", $$v)},expression:"settings.activeTill"}})],1)],1):_vm._e(),_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('v-select',{attrs:{"readonly":_vm.readonly,"items":_vm.abTestMetricTypes,"label":_vm.$lang('abTestConfig.abTestMetricType'),"rules":_vm.requiredArrayRule},model:{value:(_vm.settings.metric),callback:function ($$v) {_vm.$set(_vm.settings, "metric", $$v)},expression:"settings.metric"}})],1)]:_vm._e(),(_vm.isAbTypeAudience)?_c('v-col',{attrs:{"sm":"12","md":"6","atid":"assignment_till_view"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var onTooltip = ref.on;
return [_c('v-text-field',_vm._g({attrs:{"label":_vm.$lang('commonConfig.assignmentTill'),"readonly":"","persistent-hint":"","prepend-icon":"event","atid":"assignment_till_field"},model:{value:(_vm.settings.assignmentTillDate),callback:function ($$v) {_vm.$set(_vm.settings, "assignmentTillDate", $$v)},expression:"settings.assignmentTillDate"}},
                  _vm.readonly && !_vm.editable ? null : Object.assign({}, onMenu, onTooltip)
                ))]}}],null,true)},[_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.$lang("abTestConfig.inputAssignmentHint")))])])]}}],null,false,73361176),model:{value:(_vm.menuAssignmentTill),callback:function ($$v) {_vm.menuAssignmentTill=$$v},expression:"menuAssignmentTill"}},[_c('v-date-picker',{attrs:{"no-title":"","min":_vm.minAssignmentTill,"first-day-of-week":1,"atid":"date_picker_assignment_till"},on:{"input":function($event){_vm.menuAssignmentTill = false}},model:{value:(_vm.settings.assignmentTillDate),callback:function ($$v) {_vm.$set(_vm.settings, "assignmentTillDate", $$v)},expression:"settings.assignmentTillDate"}})],1)],1):_vm._e(),_c('v-col',{attrs:{"sm":"12","md":"6"}},[_c('v-select',{attrs:{"readonly":_vm.readonly,"items":_vm.dayNumbers,"label":_vm.$lang('abTestConfig.dayNumber'),"rules":_vm.requiredRule},model:{value:(_vm.settings.dayNumber),callback:function ($$v) {_vm.$set(_vm.settings, "dayNumber", $$v)},expression:"settings.dayNumber"}})],1)],2)],1)}
var staticRenderFns = []

export { render, staticRenderFns }