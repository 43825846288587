














































































































































































































































































































































































































































































































































































































































































































































import { Component, Vue, Ref, Prop, Watch } from "vue-property-decorator";
import { cloneDeep, isEqual, unset } from "lodash";
import { DataTableHeader } from "vuetify";

import ConfigResponseDeployer from "@/ab-tests/components/ConfigResponseDeployer.vue";
import ConfigResponseDialog from "@/ab-tests/components/ConfigResponseDialog.vue";
import ConfigResponseEditorValue from "@/ab-tests/components/ConfigResponseEditorValue.vue";
import ApplicationResponseModel, {
  EnvironmentModel,
  EnvironmentType,
} from "@/ab-tests/models/ApplicationResponseModel";
import {
  ConfigResponseDataTableItem,
  ConfigResponseEditedItemModel,
  CONFIGS_COLORS,
  ConfigResponseOverwrittenModel,
  ConfigsDiffResponseModel,
  ConfigsDiffModel,
  ConfigValueType,
  ConfigType,
  STAGE_PROP_TYPE,
  ConfigStageResponseModel,
} from "@/ab-tests/models/ConfigResponseModel";
import AbTestResponseModel from "@/ab-tests/models/AbTestResponseModel";
import TargetedConfigurationModel from "@/ab-tests/models/TargetedConfigurationModel";
import DefaultConfigurationModel from "@/ab-tests/models/DefaultConfigurationModel";
import ItemPreviewModel, {
  PREVIEW_ITEM_TYPE,
} from "@/ab-tests/models/ItemPreviewModel";
import JsonUtil from "@/ab-tests/utils/JsonUtil";
import ValidUtil from "@/shared/utils/ValidUtil";
import { VueForm } from "@/shared/types/ExtendedVueType";
import { AppSection } from "@/shared/models";

@Component({
  components: {
    ConfigResponseDeployer,
    ConfigResponseDialog,
    ConfigResponseEditorValue,
  },
})
export default class ConfigResponseEditor extends Vue {
  @Prop() value!: string;
  @Prop({ default: "tab-stage" }) activeTab!: string;
  @Prop() defaultConfigResponse!: ApplicationResponseModel;
  @Prop() initialConfig!: ApplicationResponseModel;
  @Prop() abTestResponses?: Array<AbTestResponseModel>;
  @Prop({ default: true }) isDeployerVisible!: boolean;
  @Prop({ default: true }) editable!: boolean;
  @Prop() configParentId!: number;
  @Prop({ default: false }) hasNonDeployedChanges!: boolean;
  @Prop({ default: false }) isDeploymentInProgress!: boolean;
  @Prop() previewItemsAfterTransfer!: Array<ItemPreviewModel>;
  @Prop() hasDeployAccess!: boolean;

  @Ref("form") readonly form!: VueForm;
  @Ref("fileInput") readonly fileInput!: any;
  @Ref("firstTd") firstTd!: any;
  @Ref("secondTd") secondTd!: any;
  @Ref("thirdTd") thirdTd!: any;

  expanded: Array<ConfigResponseDataTableItem> = [];
  nestedExpanded: Array<ConfigResponseOverwrittenModel> = [];
  selected: Array<ConfigResponseDataTableItem> = [];
  previewItems: Array<ItemPreviewModel> = [];
  dialogDelete = false;
  dialogEdit = false;
  edited = false;
  deletedItem: ConfigResponseDataTableItem | null = null;
  editedItem = new ConfigResponseEditedItemModel(
    "",
    "",
    "",
    ConfigValueType.STRING
  );
  initialEditedItemKey = "";
  search = "";
  editedIndex = 0;
  isValid = true;
  isDeleteSelected = false;
  isSaveAndMore = false;
  useHierarchy = false;
  isParentConfigShowned = true;
  isParentItemClicked = false;
  ConfigValueType = ConfigValueType;
  ConfigType = ConfigType;

  readonly STAGE_PROP_TYPE = STAGE_PROP_TYPE;
  readonly requiredRule = [
    ValidUtil.required(this.$lang("validation.required")),
  ];
  readonly rulesForDescription = [
    ValidUtil.maxLength(200, this.$lang("validation.maxLength", 200)),
  ];
  readonly valueTypes = [
    "string",
    "array",
    "object",
    "float",
    "integer",
    "boolean",
  ];
  readonly menuItems = [
    {
      id: 1,
      text: this.$lang("configResponseEditor.goToAbTest"),
      configType: ConfigType.AB_TEST,
    },
    {
      id: 2,
      text: this.$lang("configResponseEditor.goToTargetedConfig"),
      configType: ConfigType.TARGETED,
    },
    {
      id: 3,
      text: this.$lang("configResponseEditor.goToDefaultConfig"),
      configType: ConfigType.DEFAULT,
    },
    {
      id: 4,
      text: this.$lang("configResponseEditor.goToResponses"),
      configType: null,
    },
  ];

  get isStage(): boolean {
    return this.activeTab === "tab-stage";
  }

  get headers(): Array<DataTableHeader> {
    return [
      { text: "", value: "data-table-select" },
      { text: "", value: "data-table-expand" },
      {
        text: this.$lang("jsonEditor.key"),
        align: "start",
        value: "key",
      },
      {
        text: this.$lang("jsonEditor.overwritten"),
        value: "overwritten",
        sortable: false,
      },
      {
        text: this.$lang("jsonEditor.value"),
        value: "value",
        sortable: false,
      },
      ...(this.isStage
        ? [
            {
              text: this.$lang("jsonEditor.prodValue"),
              value: "prodValue",
              sortable: false,
            },
          ]
        : []),
      ...(this.editable
        ? [
            {
              text: this.$lang("jsonEditor.actions"),
              value: "actions",
              sortable: false,
            },
          ]
        : []),
    ];
  }

  get expandedHeaders(): Array<DataTableHeader> {
    return this.headers.map((item: DataTableHeader) => ({ ...item, text: "" }));
  }

  get colorForShowParentConfigsBtn(): string {
    if (this.dark && !this.isParentConfigShowned) {
      return "secondary";
    } else if (!this.dark && !this.isParentConfigShowned) {
      return "";
    }

    return "primary";
  }

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  get applicationId(): string {
    return this.$route.params.id;
  }

  get isDisabledSaveButton(): boolean {
    return !this.isValid || !this.editedItem.key;
  }

  get inputErrorMessages(): Array<string> {
    const notUnique = this.items.find(
      (item) => item.key === this.editedItem.key && !item.isProdItem
    );

    if (
      (this.edited || this.isParentItemClicked) &&
      this.initialEditedItemKey === this.editedItem.key
    ) {
      return [];
    }

    if (notUnique) {
      return [this.$lang("validation.unique")];
    }

    return [];
  }

  get isDefaultConfigPage(): boolean {
    return this.$route.name === AppSection.DEFAULT_CONFIG;
  }

  get isAbTestConfigPage(): boolean {
    return this.$route.name === "abTest_edit_configs";
  }

  get targetedConfig(): TargetedConfigurationModel {
    return this.$store.state.targetedConfig.targetedConfig;
  }

  get defaultConfig(): DefaultConfigurationModel {
    return this.$store.state.defaultConfig.config;
  }

  get items(): Array<ConfigResponseDataTableItem> {
    if (!this.value) {
      return [];
    }

    const value: Array<ConfigStageResponseModel> = JsonUtil.formattingFromJson(
      JsonUtil.formatting(this.value)
    );

    let items: Array<ConfigResponseDataTableItem> = value.map((item) => ({
      ...item,
      overwritten: this.getOverwrittenItems(item.key),
    }));

    if (this.isStage) {
      const prodItems =
        JsonUtil.formattingFromJson(
          JsonUtil.formatting(this.defaultConfigResponse.production.response)
        ) || [];

      prodItems.forEach((prodItem: ConfigResponseDataTableItem) => {
        const stageItem = items.find(
          ({ key, isParent }) => prodItem.key === key && !isParent
        );

        if (stageItem) {
          stageItem.prodValue = prodItem.value;
          stageItem.stagePropType = isEqual(stageItem.value, prodItem.value)
            ? STAGE_PROP_TYPE.SAME
            : STAGE_PROP_TYPE.EDITED;
        } else {
          items.push({
            ...prodItem,
            prodValue: prodItem.value,
            stagePropType: STAGE_PROP_TYPE.MISSING,
            isProdItem: true,
          });
        }
      });

      items.forEach((item) => {
        if (item.stagePropType || item.isParent) {
          return;
        }

        item.stagePropType = STAGE_PROP_TYPE.NEW;
      });
    }

    if (!this.isDefaultConfigPage) {
      if (this.isParentConfigShowned) {
        items.push(
          ...this.getParentConfigs(
            this.isStage ? "stage" : "production"
          ).filter(
            (parentItem) => !value.find((item) => item.key === parentItem.key)
          )
        );
      } else {
        items = items.filter(({ isParent }) => !isParent);
      }
    }

    return items;
  }

  get configsDiff(): Array<ConfigsDiffResponseModel> {
    return this.$store.state.configsStore.configsDiff;
  }

  get titleForDialogDelete(): string {
    if (this.isDeleteSelected) {
      const deletedItems = this.selected.map((item) => item.key).join(", ");
      return this.$lang("configResponseEditor.dialogDeleteTitle", deletedItems);
    }

    return this.$lang(
      "configResponseEditor.dialogDeleteTitle",
      this.deletedItem?.key as string
    );
  }

  get formTitle(): string {
    return this.isParentItemClicked
      ? this.$lang("configResponseEditor.form.overwriteItemTitle")
      : this.edited
      ? this.$lang("configResponseEditor.form.editItemTitle")
      : this.$lang("configResponseEditor.form.newItemTitle");
  }

  get duplicateConfigs(): boolean {
    const key = this.activeTab.split(
      "tab-"
    )[1] as keyof ApplicationResponseModel;

    return !!this.abTestResponses
      ?.filter(
        (response) =>
          JsonUtil.formattingFromJson(
            JsonUtil.formatting(
              String((response[key] as EnvironmentModel).response)
            )
          ).length
      )
      .filter((response) => response.id !== this.defaultConfigResponse.id)
      .find(
        (response) =>
          (response[key] as EnvironmentModel).response === this.value
      );
  }

  get version(): number {
    return this.isStage
      ? this.defaultConfigResponse.stage?.version ?? 0
      : this.defaultConfigResponse.production?.version ?? 0;
  }

  get widthForFirstNestedTd() {
    return this.firstTd ? `${this.firstTd.clientWidth}px` : "";
  }

  get widthForSecondNestedTd() {
    return this.secondTd ? `${this.secondTd.clientWidth}px` : "";
  }

  get widthForThirdNestedTd() {
    return this.thirdTd ? `${this.thirdTd.clientWidth}px` : "";
  }

  get isDisabledParentButton(): boolean {
    return !this.$store.getters.existsDefaultConfig;
  }

  get borderBottom(): string {
    return `thin solid rgba(${
      this.$vuetify.theme.dark ? "255, 255, 255" : "0, 0, 0"
    }, 0.12)`;
  }

  @Watch("$route", { immediate: true, deep: true })
  async watchRouter() {
    await this.$store.dispatch("loadConfigsDiff", {
      params: {
        configId: this.$route.params.configId,
        environmentType: this.isStage
          ? EnvironmentType.STAGE
          : EnvironmentType.PROD,
      },
      needUpdateConfigsDiff: true,
    });
  }

  @Watch("useHierarchy")
  async watchUseHierarchy(value: boolean) {
    await this.$store.dispatch("loadConfigsDiff", {
      params: {
        configId: this.$route.params?.configId ?? "",
        useHierarchy: value,
        environmentType: this.isStage
          ? EnvironmentType.STAGE
          : EnvironmentType.PROD,
      },
      needUpdateConfigsDiff: true,
    });
  }

  @Watch("previewItemsAfterTransfer", { deep: true, immediate: true })
  private watchPreviewItemsAfterTransfer(value: Array<ItemPreviewModel>) {
    this.previewItems = [...value];
  }

  async mounted() {
    if (
      this.isAbTestConfigPage &&
      this.configParentId !== this.defaultConfig.id
    ) {
      await this.$store.dispatch("getTargetedConfig", this.configParentId);
    }
  }

  destroyed() {
    this.$store.commit("clearConfigsDiff");
  }

  customSearch(
    _: any,
    search: string,
    item: ConfigResponseDataTableItem
  ): boolean {
    return item.key.toLowerCase().includes(search.toLowerCase());
  }

  preventDoubleQuotes(event: KeyboardEvent) {
    if (event.code === "Quote") {
      event.preventDefault();
    }
  }

  getParentConfigs(
    field: keyof ApplicationResponseModel
  ): Array<ConfigResponseDataTableItem> {
    if (this.editable) {
      const defaultConfigResponse =
        JsonUtil.formattingFromJson(
          JsonUtil.formatting(
            (this.defaultConfig.response as any)[field].response
          )
        ) || [];

      if (this.configParentId === this.defaultConfig.id) {
        if (defaultConfigResponse.length) {
          return [
            ...defaultConfigResponse.map(
              (item: ConfigResponseDataTableItem) => ({
                ...item,
                isParent: true,
                overwritten: this.getOverwrittenItems(item.key),
              })
            ),
          ];
        }
      } else {
        const targetedConfigResponse = JsonUtil.formattingFromJson(
          JsonUtil.formatting((this.targetedConfig.response as any)[field])
        );

        if (targetedConfigResponse.length) {
          const filteredDefaultConfigResponse = defaultConfigResponse.filter(
            (item: ConfigResponseDataTableItem) => {
              return !targetedConfigResponse.some(
                (targetItem: ConfigResponseDataTableItem) =>
                  targetItem.key === item.key
              );
            }
          );

          return [
            ...targetedConfigResponse,
            ...filteredDefaultConfigResponse,
          ].map((item: ConfigResponseDataTableItem) => ({
            ...item,
            isParent: true,
            overwritten: this.getOverwrittenItems(item.key),
          }));
        } else {
          return [
            ...defaultConfigResponse.map(
              (item: ConfigResponseDataTableItem) => ({
                ...item,
                isParent: true,
                overwritten: this.getOverwrittenItems(item.key),
              })
            ),
          ];
        }
      }
    }

    return [];
  }

  getOverwrittenItems(key: string): Array<ConfigResponseOverwrittenModel> {
    const overwritten: Array<ConfigResponseOverwrittenModel> = [];

    this.configsDiff.forEach((item: ConfigsDiffResponseModel) => {
      const found = item.diff.find(
        (diffItem: ConfigsDiffModel) => diffItem.key === key
      );

      if (found) {
        overwritten.push({
          configId: item.configId,
          configName:
            !item.configName && item.configType === ConfigType.DEFAULT
              ? "Default config"
              : item.configName,
          segmentName: item.segmentName,
          configType: item.configType,
          value: JSON.stringify(found.value),
          chipColor: (CONFIGS_COLORS as Record<string, string>)[
            item.configType
          ],
          link: this.getLinkForOverwrittenItem(item.configType, item.configId),
          isMulti: false,
          children: [],
        });
      }
    });

    return overwritten
      .reduce((result: any, overwrittenItem: any) => {
        const found = result.find(
          (item: any) => item.value === overwrittenItem.value
        );

        if (!found) {
          return [...result, overwrittenItem];
        } else {
          if (!found.isMulti) {
            const parentItem = cloneDeep(found);
            unset(parentItem, "children");
            unset(parentItem, "isMulti");

            found.isMulti = true;
            found.children = [parentItem, overwrittenItem];
          } else {
            found.children = [...found.children, overwrittenItem];
          }

          return [...result];
        }
      }, [])
      .map((item) => {
        if (item.isMulti) {
          return {
            children: item.children,
            isMulti: item.isMulti,
            value: item.value,
          };
        }

        return item;
      });
  }

  getLinkForOverwrittenItem(
    configType: ConfigType,
    configId: number | string
  ): string {
    switch (configType) {
      case ConfigType.TARGETED:
        return `/app/${this.applicationId}/ab-tests/targeted/${configId}/view`;
      case ConfigType.AB_TEST:
        return `/app/${this.applicationId}/ab-tests/${configId}/view`;
      case ConfigType.DEFAULT:
        return `/app/${this.applicationId}/ab-tests/default`;
      default:
        return "";
    }
  }

  canExpand(item: ConfigResponseDataTableItem): boolean {
    return !!item?.overwritten?.length;
  }

  isMultiValue(item: any): boolean {
    return !!item.isMulti;
  }

  getOverwrittenCount(overwritten: any): number {
    return overwritten.reduce((result: number, item: any) => {
      if (this.isMultiValue(item)) {
        return result + item.children.length;
      }

      return result + 1;
    }, 0);
  }

  closeDialogEdit() {
    if (this.isParentItemClicked) {
      this.isParentItemClicked = false;
    }

    this.dialogEdit = false;
    this.form.resetValidation();
    this.editedItem = new ConfigResponseEditedItemModel(
      "",
      "",
      "",
      ConfigValueType.STRING
    );

    if (this.edited) {
      this.edited = false;
    }
  }

  async saveForm() {
    this.form.validate();

    if (this.isValid) {
      const tempItems: Array<{
        key: string;
        value: any;
        desc: string;
        type: string;
      }> = this.items
        .filter(({ isParent, isProdItem }) => !isParent && !isProdItem)
        .map((item) => ({
          key: item.key,
          value: item.value,
          desc: item.desc,
          type: item.type,
        }));

      if (this.edited) {
        const foundItem = tempItems[this.editedIndex];

        if (foundItem) {
          this.handleEditedItemForPreviewItems(foundItem.key, this.editedItem);

          foundItem.key = this.editedItem.key;
          foundItem.value =
            (this.editedItem.type === ConfigValueType.ARRAY ||
              this.editedItem.type === ConfigValueType.OBJECT) &&
            typeof this.editedItem.value === "string"
              ? JSON.parse(this.editedItem.value as string)
              : this.editedItem.value;
          foundItem.desc = this.editedItem.desc;
          foundItem.type = this.editedItem.type;
        }
      } else {
        let value: any = null;

        if (
          this.editedItem.type === ConfigValueType.ARRAY ||
          this.editedItem.type === ConfigValueType.OBJECT
        ) {
          if (
            typeof JSON.parse(JSON.stringify(this.editedItem.value)) ===
            "string"
          ) {
            value = JSON.parse(
              JSON.parse(JSON.stringify(this.editedItem.value))
            );
          } else {
            value = JSON.parse(JSON.stringify(this.editedItem.value));
          }
        } else {
          value = this.editedItem.value;
        }

        tempItems.push({
          ...this.editedItem,
          value,
        });
        this.previewItems.push({
          key: this.editedItem.key,
          value,
          type: PREVIEW_ITEM_TYPE.ADDED,
        });
      }

      this.$emit("save", JsonUtil.formattingToString(tempItems));

      if (this.isSaveAndMore) {
        this.editedItem.clearData();
        this.form.resetValidation();
      } else {
        this.closeDialogEdit();
      }

      this.handleLoadConfigsDiff(tempItems);
    }
  }

  async handleLoadConfigsDiff(
    items: Array<{
      key: string;
      value: any;
      desc: string;
      type: string;
    }>
  ) {
    const keys = items.map((item) => item.key);
    const values = items.map((item) => item.value);

    await this.$store.dispatch("loadConfigsDiff", {
      params: {
        configId: this.$route.params?.configId ?? "",
        key: keys[keys.length - 1],
        value:
          items[items.length - 1].type === "object"
            ? JSON.stringify(values[values.length - 1])
            : String(values[values.length - 1]),
        useHierarchy: this.useHierarchy,
        environmentType: this.isStage
          ? EnvironmentType.STAGE
          : EnvironmentType.PROD,
      },
      needUpdateConfigsDiff: false,
    });
  }

  handleItemEditing(value: ConfigResponseDataTableItem, isEdit: boolean) {
    if (isEdit) {
      this.editedIndex = this.items.findIndex((item) => item.key === value.key);
      this.edited = true;
      this.isSaveAndMore = false;
    }

    this.initialEditedItemKey = value.key;
    this.editedItem = new ConfigResponseEditedItemModel(
      value.key,
      value.value,
      value.desc,
      value.type
    );

    this.dialogEdit = true;
  }

  handleItemDeleting(value: ConfigResponseDataTableItem) {
    this.deletedItem = value;
    this.dialogDelete = true;
  }

  deleteSelected() {
    this.isDeleteSelected = true;
    this.dialogDelete = true;
  }

  closeDelete() {
    this.dialogDelete = false;
  }

  deleteItemConfirm() {
    let tempItems: Array<ConfigResponseEditedItemModel> = this.items
      .filter(({ isParent, isProdItem }) => !isParent && !isProdItem)
      .map(
        (item) =>
          new ConfigResponseEditedItemModel(
            item.key,
            item.value,
            item.desc,
            item.type
          )
      );

    if (this.isDeleteSelected) {
      this.selected.forEach((selectedItem) => {
        tempItems = tempItems.filter((item) => item.key !== selectedItem.key);

        this.handleDeletedItemForPreviewItems(selectedItem);
      });
      this.selected = [];
      this.isDeleteSelected = false;
    } else {
      tempItems = tempItems.filter(
        (item) => item.key !== this.deletedItem?.key
      );

      this.handleDeletedItemForPreviewItems(
        this.deletedItem as ConfigResponseDataTableItem
      );
      this.deletedItem = null;
    }

    this.$emit("save", JsonUtil.formattingToString(tempItems));

    this.closeDelete();
  }

  handleDeletedItemForPreviewItems({
    key,
    value,
  }: {
    key: string;
    value: any;
  }) {
    const found = this.previewItems.find((item) => item.key === key);
    if (found && found.type !== PREVIEW_ITEM_TYPE.EDITED) {
      this.previewItems = this.previewItems.filter((item) => item.key !== key);
    } else if (found && found.type === PREVIEW_ITEM_TYPE.EDITED) {
      found.type = PREVIEW_ITEM_TYPE.DELETED;
    } else {
      this.previewItems.push({
        key,
        value,
        type: PREVIEW_ITEM_TYPE.DELETED,
      });
    }
  }

  handleEditedItemForPreviewItems(
    editableItemKey: string,
    { key, value }: { key: string; value: any }
  ) {
    const found = this.previewItems.find(
      (item) => item.key === editableItemKey
    );

    if (found) {
      found.key = key;
      found.value = value;
    } else {
      this.previewItems.push({
        key,
        value,
        type: PREVIEW_ITEM_TYPE.EDITED,
      });
    }
  }

  checkValidValue(valid: boolean) {
    this.isValid = valid;
  }

  handleExportJSONFile() {
    try {
      const formattedData = JsonUtil.formattingFromJson(this.value).reduce(
        (
          result: Record<string, any>,
          currentItem: ConfigResponseEditedItemModel
        ) => ({
          ...result,
          [currentItem.key]: currentItem.value,
        }),
        {}
      );

      let dataStr = JSON.stringify(formattedData);
      let dataUri =
        "data:application/json;charset=utf-8," + encodeURIComponent(dataStr);

      let exportFileDefaultName = "data.json";

      let linkElement = document.createElement("a");
      linkElement.setAttribute("href", dataUri);
      linkElement.setAttribute("download", exportFileDefaultName);
      linkElement.click();

      this.$store.commit("addNotification", {
        message: {
          key: "jsonEditor.notification.successExport",
        },
      });
    } catch {
      this.$store.dispatch(
        "addError",
        this.$lang("jsonEditor.notification.errorExport")
      );
    }
  }

  handleFileChange(event: any) {
    const reader = new FileReader();
    reader.onload = this.handleReaderLoad;
    reader.readAsText(event.target.files[0]);
    // This for upload same file twice
    event.srcElement.value = "";
  }

  copyResponse(value: {
    payload: ApplicationResponseModel;
    field: "stage" | "production";
  }) {
    this.fillPreviewItemsAfterImportConfig(value.payload[value.field].response);
    this.$emit("copyResponse", value);
  }

  handleReaderLoad(event: any) {
    try {
      const value = JSON.parse(event.target.result);

      this.fillPreviewItemsAfterImportConfig(
        this.formattedDataForImportJSON(value)
      );
      this.$emit("import", this.formattedDataForImportJSON(value));

      this.$store.commit("addNotification", {
        message: {
          key: "jsonEditor.notification.successImport",
        },
      });
    } catch {
      this.$store.dispatch(
        "addError",
        this.$lang("jsonEditor.notification.errorImport")
      );
    }
  }

  formattedDataForImportJSON(value: Record<string, any>) {
    return JsonUtil.formattingToString(
      Object.entries(value).reduce(
        (res: Array<Record<string, any>>, [key, value]: [string, any]) => {
          res.push({
            key,
            value,
            desc: "",
            type: this.defineValueType(value),
          });

          return res;
        },
        []
      )
    );
  }

  defineValueType(value: any) {
    if (Array.isArray(value)) {
      return "array";
    } else if (
      typeof value === "number" &&
      !Number.isNaN(value) &&
      !Number.isInteger(value)
    ) {
      return "float";
    } else if (typeof value === "number" && Number.isInteger(value)) {
      return "integer";
    } else {
      return typeof value;
    }
  }

  fillPreviewItemsAfterImportConfig(importedConfig: string) {
    this.previewItems = [
      ...JsonUtil.formattingFromJson(this.initialConfig.stage.response)
        .filter(({ key }: { key: string }) =>
          JsonUtil.formattingFromJson(importedConfig).every(
            (item: any) => item.key !== key
          )
        )
        .map(({ key, value }: { key: string; value: string }) => ({
          key,
          value,
          type: PREVIEW_ITEM_TYPE.DELETED,
        })),
      ...JsonUtil.formattingFromJson(importedConfig)
        .filter(({ key, value }: { key: string; value: string }) =>
          JsonUtil.formattingFromJson(this.initialConfig.stage.response).some(
            (item: any) => item.key === key && item.value !== value
          )
        )
        .map(({ key, value }: { key: string; value: string }) => ({
          key,
          value,
          type: PREVIEW_ITEM_TYPE.EDITED,
        })),
      ...JsonUtil.formattingFromJson(importedConfig)
        .filter(({ key }: { key: string }) =>
          JsonUtil.formattingFromJson(this.initialConfig.stage.response).every(
            (item: any) => item.key !== key
          )
        )
        .map(({ key, value }: { key: string; value: string }) => ({
          key,
          value,
          type: PREVIEW_ITEM_TYPE.ADDED,
        })),
    ];
  }

  handleOpenFileInput() {
    this.fileInput.click();
  }

  handleShowParentConfigs() {
    this.isParentConfigShowned = !this.isParentConfigShowned;
  }

  handleClickByParentRow(item: any) {
    this.handleItemEditing(item, false);
    this.dialogEdit = true;
    this.isParentItemClicked = true;
  }

  getBackground(type?: STAGE_PROP_TYPE): string {
    if (!type) {
      return "";
    }

    return {
      NEW: "#4CAF5044",
      SAME: "",
      MISSING: "#F4433644",
      EDITED: "#FFEB3B44",
    }[type];
  }

  getItemValue(item: ConfigResponseDataTableItem, field = "stage"): string {
    const value = field === "prod" ? item.prodValue ?? "" : item.value ?? "";

    return typeof value === "string"
      ? JSON.parse(JSON.stringify(value))
      : JSON.stringify(value);
  }

  customSort(
    items: Array<ConfigResponseDataTableItem>,
    [index]: Array<string>,
    [isDesc]: Array<boolean>
  ): Array<ConfigResponseDataTableItem> {
    const mappedItems = items.reduce(
      (
        res: Array<Array<ConfigResponseDataTableItem>>,
        item: ConfigResponseDataTableItem
      ) => {
        if (item.isParent) {
          return [[...res[0]], [...res[1], item]];
        } else {
          return [[...res[0], item], [...res[1]]];
        }
      },
      [[], []]
    );

    for (let i = 0; i < mappedItems.length; i++) {
      mappedItems[i].sort((a: any, b: any) => {
        if (!isDesc) {
          return a[index] < b[index] ? -1 : 1;
        } else {
          return b[index] < a[index] ? -1 : 1;
        }
      });
    }

    return mappedItems.flat();
  }

  emitDeploy(value: boolean) {
    this.$emit("deploy", value);
    this.previewItems = [];
  }
}
