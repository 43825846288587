












































































































import { Component, Watch, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import { Route, NavigationGuardNext } from "vue-router";

import AbTestConfigSettings from "@/ab-tests/components/AbTestConfigSettings.vue";
import AbTestConfigResponseItems from "@/ab-tests/components/AbTestConfigResponseItems.vue";
import Rules from "@/ab-tests/components/Rules.vue";
import SaveConfirmDialog from "@/ab-tests/components/SaveConfirmDialog.vue";
import AbTestConfigUsersCount from "@/ab-tests/components/AbTestConfigUsersCount.vue";
import AbTestConfigurationModel, {
  AbTestMinUsersCount,
  AbTestMinUsersCountRequest,
  AbTestType,
} from "@/ab-tests/models/AbTestConfigurationModel";
import AbTestConfigStore from "@/ab-tests/store/AbTestConfigStore";
import ConfigUtil from "@/ab-tests/utils/ConfigUtil";
import { AppSection } from "@/shared/models";
import UnsavedChangesMixin from "@/shared/mixins/UnsavedChangesMixin";
import DateUtil from "@/shared/utils/DateUtil";

@Component({
  components: {
    Rules,
    AbTestConfigSettings,
    AbTestConfigResponseItems,
    SaveConfirmDialog,
    AbTestConfigUsersCount,
  },
})
export default class AbTestConfigurationEditView extends mixins(
  UnsavedChangesMixin
) {
  store: AbTestConfigStore = this.$store.state.abTestConfig;
  abTestConfig: AbTestConfigurationModel = new AbTestConfigurationModel();
  existingNames: Array<string> = [];
  isWatchedAbTest = false;
  saveDialog = false;
  fromRoute: Route | null = null;
  usersCountChanged = false;
  minUsersCountDisabled = true;
  tabValue = null;

  get editable(): boolean {
    return this.abTestConfig.editable && this.abTestConfig.hasEditAccess;
  }

  get instanceForWatchingUnsavedChanges() {
    // in order to receive the old and new value in the watch during deep viewing
    return Object.assign({}, this.abTestConfig);
  }

  get hasDuplicateConfigs(): boolean {
    const configs = this.abTestConfig.responses.map((it) => it.stage);
    return new Set(configs).size !== configs.length;
  }

  get maxDateInRules() {
    return ConfigUtil.maxDateInRules(this.abTestConfig.rules);
  }

  get maxInstallDate(): string | undefined {
    if (this.abTestConfig.abTestType === AbTestType.AUDIENCE) {
      return DateUtil.twoDaysAgo();
    }

    return this.abTestConfig.activeTill;
  }

  get configId(): number {
    return Number.parseInt(this.$route.params.configId);
  }

  get deployed(): boolean {
    return this.abTestConfig.responses.every(
      (it) => it.stage === it.production
    );
  }

  get abTestConfigResponseOrigins() {
    return this.store.abTestConfig ? this.store.abTestConfig.responses : [];
  }

  get hasUnsavedChanges(): boolean {
    return this.$store.state.unsavedChangesStore.hasUnsavedChanges;
  }

  get appId(): string {
    return this.$route.params.id;
  }

  @Watch("abTestConfig.minUsersCountRequest", { deep: true })
  watchMinUsersCountReuqest(
    _: AbTestMinUsersCountRequest,
    oldRequest: AbTestMinUsersCountRequest | null
  ) {
    if (!oldRequest) {
      return;
    }

    this.abTestConfig.minUsersCount = undefined;
    this.minUsersCountDisabled = false;
    this.usersCountChanged = false;
  }

  @Watch("configId", { immediate: true })
  async watchConfigId(configId: number) {
    await this.$store.dispatch("getAbTestConfig", configId);
    this.$store.dispatch("loadUsedDictionaries", {
      app: this.applicationId,
      values: this.abTestConfig.getUsedDictionaryValues(),
    });
  }

  @Watch("store.abTestConfig", { deep: true })
  private watchAbTestConfig(abTestConfig: AbTestConfigurationModel) {
    this.abTestConfig = AbTestConfigurationModel.of(abTestConfig);
    this.initNames();

    if (!this.isWatchedAbTest) {
      this.isWatchedAbTest = true;
      this.handleWatchingUnsavedChanges();
    }
  }

  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext) {
    next((vm: Vue & { fromRoute?: Route }) => {
      vm.fromRoute = from;
    });
  }

  beforeRouteLeave(to: Route, from: Route, next: NavigationGuardNext) {
    if (
      this.hasUnsavedChanges &&
      this.currentRoutePath === from.path &&
      !this.isSavedForm
    ) {
      this.showUnsavedChangesDialog(to);
    } else {
      next();
    }
  }

  goBack() {
    this.$router.push({
      name: this.fromRoute?.name ?? AppSection.AB_TESTS,
    });
  }

  initNames() {
    this.existingNames = this.$store.getters["getAbTestConfigNames"].slice();
    if (this.abTestConfig.name) {
      const index = this.existingNames.indexOf(
        this.abTestConfig.name.toLowerCase()
      );
      if (index !== -1) this.existingNames.splice(index, 1);
    }
  }

  handleSave() {
    if (this.hasDuplicateConfigs) {
      this.saveDialog = true;
    } else {
      this.save();
    }
  }

  async save() {
    await this.$store.dispatch("updateAbTestConfig", this.abTestConfig);
    this.isSavedForm = true;
    this.$router.push({
      name: AppSection.AB_TESTS,
    });
  }

  handleSaveConfigs() {
    this.save();
    this.saveDialog = false;
  }

  updateMinUsersCount(minUsersCount?: AbTestMinUsersCount) {
    this.abTestConfig.minUsersCount = minUsersCount;
  }
}
