export enum PREVIEW_ITEM_TYPE {
  ADDED = "ADDED",
  EDITED = "EDITED",
  DELETED = "DELETED",
}

export default class ItemPreviewModel {
  constructor(
    public key: string,
    public value: any,
    public type: PREVIEW_ITEM_TYPE
  ) {}
}
