











import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class CsvFileView extends Vue {
  @Prop() csvFileId?: number;
  @Prop() appId!: string;

  get baseUrl() {
    return this.axios.defaults.baseURL || "";
  }
}
