













import { Component, Vue, Prop } from "vue-property-decorator";

import { Dictionary } from "../models";

@Component
export default class Lat extends Vue {
  @Prop({ default: null }) value!: boolean | null;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: false }) dense!: boolean;
  @Prop({ default: false }) outlined!: boolean;
  @Prop({ default: () => [] }) rules!: Array<any>;

  readonly items: Array<Dictionary> = [
    {
      value: null,
      text: this.$lang(`lat.values.any`),
    },
    {
      value: true,
      text: this.$lang(`lat.values.on`),
    },
    {
      value: false,
      text: this.$lang(`lat.values.off`),
    },
  ];

  get localValue(): boolean | null {
    return this.value;
  }

  set localValue(value: boolean | null) {
    this.$emit("input", value);
  }
}
