












































































import * as am5 from "@amcharts/amcharts5";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Light from "@amcharts/amcharts5/themes/Material";
import * as am5xy from "@amcharts/amcharts5/xy";
import { Component, Prop, Ref, Vue, Watch } from "vue-property-decorator";

import AbTestConfigurationModel, {
  AbTestMinUsersCountResponse,
  AbTestMinUsersCountType,
  AbTestMinUsersCountValue,
} from "@/ab-tests/models/AbTestConfigurationModel";
import EventSourceWrapper from "@/shared/utils/EventSourceWrapper";

@Component
export default class AbTestConfigUsersCount extends Vue {
  @Prop() abTestConfig!: AbTestConfigurationModel;
  @Prop() changed!: boolean;
  @Prop() active?: boolean;
  @Ref() chartElement!: HTMLElement;

  readonly PERCENTS_DELTA = 10;
  readonly DEFAULT_EFFECT_VALUE = 20;
  value: null | number = null;
  inputValue: null | number = null;
  root: am5.Root | null = null;
  chart: am5xy.XYChart | null = null;
  xAxis: am5xy.ValueAxis<am5xy.AxisRenderer> | null = null;
  yAxis: am5xy.ValueAxis<am5xy.AxisRenderer> | null = null;
  rangeAxis: am5.DataItem<am5xy.IValueAxisDataItem> | null = null;

  get applicationId(): string {
    return this.$store.state.application.applicationId;
  }

  get isMinUsersCountLoading(): boolean {
    return this.$store.state.abTestConfig.isMinUsersCountLoading;
  }

  get minUsersCount(): AbTestMinUsersCountResponse | null {
    return this.$store.state.abTestConfig.minUsersCount;
  }

  get values(): Array<AbTestMinUsersCountValue> {
    return (
      this.minUsersCount?.values?.map(
        ({ minimalDetectableRelativeEffect, amount }) => ({
          minimalDetectableRelativeEffect: Math.round(
            minimalDetectableRelativeEffect * 100
          ),
          amount,
        })
      ) || []
    );
  }

  get effectAmountMap(): Map<number, number> {
    return new Map(
      this.values.map(({ minimalDetectableRelativeEffect, amount }) => [
        minimalDetectableRelativeEffect,
        amount,
      ])
    );
  }

  get amount(): number | null {
    if (this.value === null) {
      return null;
    }

    return this.effectAmountMap.get(this.value) || null;
  }

  get percents(): Array<number> {
    return Array.from(this.effectAmountMap).map(([key]) => key);
  }

  get min(): number {
    return this.percents.length ? Math.min(...this.percents) : 0;
  }

  get max(): number {
    return this.percents.length ? Math.max(...this.percents) : 0;
  }

  get isDefaultUsersCountType(): boolean {
    return this.minUsersCount?.type === AbTestMinUsersCountType.DEFAULT;
  }

  get usersCountStatus(): string {
    if (!this.minUsersCount) {
      return "";
    }

    const count =
      this.amount !== null && !this.isDefaultUsersCountType
        ? this.amount.toLocaleString()
        : this.minUsersCount.defaultUsersCount.toLocaleString();

    return this.$lang(
      `commonConfig.usersCountStatus.${this.minUsersCount.type.toLowerCase()}`,
      count
    );
  }

  get minUsersCountEventSource(): EventSourceWrapper | null {
    return this.$store.state.abTestConfig.minUsersCountEventSource;
  }

  get dark(): boolean {
    return this.$vuetify.theme.dark;
  }

  @Watch("percents")
  setValue() {
    if (!this.percents.length) {
      return;
    }

    this.value = this.DEFAULT_EFFECT_VALUE;
  }

  @Watch("value")
  moveRangeAxis() {
    if (!this.rangeAxis || this.value === null || !this.minUsersCount) {
      return;
    }

    if (this.inputValue !== this.value) {
      this.inputValue = this.value;
    }

    this.rangeAxis.set("value", this.value);
    this.zoomAxes();

    if (!this.changed) {
      return;
    }
    this.$emit("update", {
      type: this.minUsersCount.type,
      amount: this.amount,
      minimalDetectableRelativeEffect: this.value / 100,
      standardDeviation: this.minUsersCount.standardDeviation,
      metricValue: this.minUsersCount.metricValue,
    });
  }

  @Watch("minUsersCountEventSource")
  async initChart() {
    if (!this.values.length || this.minUsersCountEventSource) {
      return;
    }

    await this.$nextTick();
    this.root = am5.Root.new(this.chartElement);
    this.root.setThemes([am5themes_Animated.new(this.root)]);
    this.chart = this.root.container.children.push(
      am5xy.XYChart.new(this.root, {})
    );

    this.xAxis = this.chart.xAxes.push(
      am5xy.ValueAxis.new(this.root, {
        renderer: am5xy.AxisRendererX.new(this.root, {}) as am5xy.AxisRenderer,
      })
    );
    this.yAxis = this.chart.yAxes.push(
      am5xy.ValueAxis.new(this.root, {
        numberFormat: "#'%'",
        renderer: am5xy.AxisRendererY.new(this.root, {}) as am5xy.AxisRenderer,
      })
    );
    const series = this.chart.series.push(
      am5xy.LineSeries.new(this.root, {
        xAxis: this.xAxis,
        yAxis: this.yAxis,
        valueYField: "minimalDetectableRelativeEffect",
        valueXField: "amount",
        tooltip: am5.Tooltip.new(this.root, {
          labelText: "{valueX}",
        }),
      })
    );
    const cursor = this.chart.set("cursor", am5xy.XYCursor.new(this.root, {}));

    cursor.lineY.set("visible", false);
    this.rangeAxis = this.yAxis.makeDataItem({});
    this.yAxis.createAxisRange(this.rangeAxis);
    this.rangeAxis?.get("grid")?.setAll({
      strokeWidth: 2,
      strokeOpacity: 1,
      visible: true,
      stroke: am5.color(0xff0000),
    });
    this.moveRangeAxis();
    series.data.setAll(this.values);
    this.setTheme();
  }

  @Watch("dark")
  setTheme() {
    if (!this.root) {
      return;
    }

    this.root.setThemes([
      this.dark
        ? am5themes_Dark.new(this.root)
        : am5themes_Light.new(this.root),
    ]);
  }

  @Watch("minUsersCount")
  watchMinUsersCount() {
    if (!this.minUsersCount || !this.isDefaultUsersCountType) {
      return;
    }

    this.$emit("update:changed", true);
    this.$emit("update", {
      type: this.minUsersCount.type,
      amount: this.minUsersCount.defaultUsersCount,
    });
  }

  @Watch("active")
  watchRequest(active: boolean) {
    if (!active || this.abTestConfig.minUsersCount) {
      return;
    }

    this.calculateMinUsersCount();
  }

  async created() {
    if (this.abTestConfig.minUsersCount?.minimalDetectableRelativeEffect) {
      this.value = Math.round(
        this.abTestConfig.minUsersCount.minimalDetectableRelativeEffect * 100
      );
      await this.initChart();

      if (this.changed) {
        setTimeout(this.zoomAxes);
      }

      return;
    }

    this.calculateMinUsersCount();
  }

  calculateMinUsersCount() {
    this.$emit("update:changed", false);
    this.$store.dispatch("calculateMinUsersCount", {
      appId: this.applicationId,
      payload: {
        dayNumber: this.abTestConfig.dayNumber,
        metric: this.abTestConfig.metric,
        rules: this.abTestConfig.rules,
        abTestType: this.abTestConfig.abTestType,
      },
    });
  }

  changeUsersCount() {
    if (this.changed) {
      return;
    }

    this.$emit("update:changed", true);
  }

  sortAsc(a: number, b: number) {
    return a - b;
  }

  zoomAxes() {
    if (this.value === null) {
      return;
    }

    const effectAmountArray = Array.from(this.effectAmountMap);
    const keys = effectAmountArray.map(([key]) => key);
    const values = effectAmountArray.map(([, value]) => value);
    const index = keys.indexOf(+this.value);
    const indexMax =
      index + this.PERCENTS_DELTA < effectAmountArray.length
        ? index + this.PERCENTS_DELTA
        : effectAmountArray.length - 1;
    const indexMin =
      index - this.PERCENTS_DELTA >= 0 ? index - this.PERCENTS_DELTA : 0;
    const xValues = [values[indexMin], values[indexMax]].sort(this.sortAsc);
    const yValues = [keys[indexMin], keys[indexMax]].sort(this.sortAsc);

    this.xAxis?.zoomToValues(xValues[0], xValues[1]);
    this.yAxis?.zoomToValues(yValues[0], yValues[1]);
  }
}
