


















































import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";

import AbTestConfigResponseItem from "@/ab-tests/components/AbTestConfigResponseItem.vue";
import TextPreview from "@/shared/components/TextPreview.vue";
import AbTestResponseModel from "@/ab-tests/models/AbTestResponseModel";
import { AbTestControlGroupModel } from "@/ab-tests/models/AbTestConfigurationModel";

@Component({
  components: {
    AbTestConfigResponseItem,
    TextPreview,
  },
})
export default class AbTestConfigResponseItems extends Vue {
  @Prop({ default: () => [] }) value!: Array<AbTestResponseModel>;
  @Prop({ default: false }) readonly!: boolean;
  @Prop({ default: 0 }) assignmentTillUsersCount!: number;

  testGroupsCount = this.readonly ? this.value.length : 1;
  localResponses: Array<AbTestResponseModel> = [];
  localControlGroupName = new AbTestControlGroupModel();

  get applicationId(): string {
    return this.$route.params.id;
  }

  get assignmentTillUserResult(): number {
    return this.assignmentTillUsersCount * (this.localResponses.length + 1);
  }

  get abTestConfig() {
    return this.$store.state.abTestConfig.abTestConfig;
  }

  @Watch("abTestConfig", { immediate: true })
  watchAbTestConfig() {
    if (!this.readonly) {
      return;
    }

    this.localControlGroupName.segmentName = this.abTestConfig.controlGroupName;
  }

  @Watch("localResponses", { deep: true })
  private watchLocalResponses() {
    this.emitChangedValue();
  }

  @Watch("testGroupsCount")
  private watchTestGroupsCount(value: number) {
    if (this.localResponses.length < value) {
      for (let i = 1; i < value; i++) {
        if (this.localResponses.length >= value) {
          return;
        }

        const response = new AbTestResponseModel();
        this.localResponses.push(response);
      }
    } else if (this.localResponses.length > value) {
      this.localResponses.splice(value, this.localResponses.length - value);
    }
  }

  @Watch("localControlGroupName.segmentName")
  private watchLocalControlGroupName(value: string) {
    if (this.readonly) {
      return;
    }

    this.$emit("changeControlGroupName", value);
  }

  created() {
    this.localResponses = [
      ...this.value.map((it) =>
        AbTestResponseModel.of({ ...it, isUniqueSegmentName: true })
      ),
      ...(!this.readonly ? [new AbTestResponseModel()] : []),
    ];
  }

  handleChangesSegmentName({
    value,
    isControlGroup,
  }: {
    value: AbTestResponseModel | AbTestControlGroupModel;
    isControlGroup: boolean;
  }) {
    this.$store
      .dispatch("checkSegmentNameByUnique", {
        name: value.segmentName,
        applicationId: this.applicationId,
      })
      .then((result: boolean) => {
        if (isControlGroup) {
          this.localControlGroupName.isUniqueSegmentName = result;
        } else {
          const foundResponse = this.localResponses.find(
            (response: AbTestResponseModel) =>
              response.key === (value as AbTestResponseModel).key
          );

          if (foundResponse) {
            foundResponse.isUniqueSegmentName = result;
          }
        }
      });
  }

  handleDeleteResponse(val: string) {
    const deleteIndex = this.localResponses.findIndex((it) => it.key === val);
    this.localResponses = this.localResponses.filter(
      (_, index) => index !== deleteIndex
    );
    this.testGroupsCount--;
  }

  @Emit("input")
  emitChangedValue() {
    return this.localResponses.map((response) =>
      AbTestResponseModel.of(response)
    );
  }
}
